import { useMatomo } from "@datapunt/matomo-tracker-react";
import * as React from "react";
import { PageRouteProps } from "./pageRoute";
import { getCurrentUser, isCiscoUser } from "../services/authService";
import { UserTracking } from "generated-proto-files/schema_gen_pb";

const excludeURLs = ["^\\/settings\\/user\\/.*"];

const trackMatomoPageEvent = (props: PageRouteProps): void => {
  const pathName = props.location?.pathname;
  if (
    (isCiscoUser() ||
      (window.xw &&
        window.xw.preferences?.getTracking() !==
          UserTracking.USER_TRACKING_ENABLED) ||
      (pathName && excludeURLs.find((url) => pathName.match(url)))) &&
    (!sessionStorage.getItem("enableTracking") ||
      sessionStorage.getItem("enableTracking") !== "true")
  ) {
    // Do not track for "*@.cisco.com, and urls which are considered sensitive like user details and opted-out users
    return;
  }
  // const orgId = getCurrentUser()?.AccessToken?.claims.org;
  const { enableLinkTracking, pushInstruction } = useMatomo();

  enableLinkTracking();
  React.useEffect(() => {
    pushInstruction("trackPageView");
    pushInstruction("setDocumentTitle", props.title);

    if (props?.location?.pathname) {
      pushInstruction("setCustomUrl", props.location?.pathname);
    }
    if (getCurrentUser()?.AccessToken?.claims.userId) {
      pushInstruction(
        "setUserId",
        getCurrentUser()?.AccessToken?.claims.userId
      );
    }
    if (document.referrer) {
      pushInstruction("setCustomDimension", "1", document.referrer);
    }
    // pushInstruction("setCustomDimension", "1", orgId); // custom dimension id setting in matomo
  }, [props.location, props.title]);
};

export const withTrackingHOC = (Component: unknown) => {
  return (props: PageRouteProps): JSX.Element => {
    trackMatomoPageEvent(props);
    // @ts-ignore
    return <Component {...props} />;
  };
};

class TrackingComponent extends React.Component<PageRouteProps> {
  render(): React.ReactNode {
    return <>{this.props.children}</>;
  }
}

export default withTrackingHOC(TrackingComponent);

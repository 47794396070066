import * as React from "react";
import "./animatedLines.scss";

interface Props {}
interface State {}

export class AnimatedLines extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    return (
      <svg width="300" height="100%" fill="none" className="xw-animated-lines">
        <g>
          <rect
            width="48"
            height="65%"
            x="49"
            y="40%"
            fill="#6FB24B"
            opacity=".1"
            rx="24"
          />
          <rect
            width="48"
            height="50%"
            x="77"
            y="5%"
            fill="#7DFF92"
            opacity=".1"
            rx="24"
          />
          <rect
            width="48"
            height="55%"
            x="113"
            y="20%"
            fill="#7ED1F6"
            opacity=".1"
            rx="24"
          />
          <rect
            width="48"
            height="40%"
            x="125"
            y="50%"
            fill="#107F43"
            opacity=".1"
            rx="24"
          />
          <rect
            width="48"
            height="60%"
            x="148"
            y="45%"
            fill="#029FD9"
            opacity=".1"
            rx="24"
          />
          <rect
            width="48"
            height="85%"
            x="185"
            y="26%"
            fill="#DDF94B"
            opacity=".1"
            rx="24"
          />
          <rect
            width="48"
            height="80%"
            x="222"
            y="18%"
            fill="#01EDD1"
            opacity=".1"
            rx="24"
          />
          <rect
            width="48"
            height="63%"
            x="252"
            y="45%"
            fill="#2FB945"
            opacity=".1"
            rx="24"
          />
          <rect
            width="48"
            height="65%"
            x="49"
            y="40%"
            fill="#6FB24B"
            opacity=".05"
            rx="24"
          />
          <rect
            width="48"
            height="50%"
            x="77"
            y="5%"
            fill="#7DFF92"
            opacity=".05"
            rx="24"
          />
          <rect
            width="48"
            height="55%"
            x="113"
            y="20%"
            fill="#7ED1F6"
            opacity=".05"
            rx="24"
          />
          <rect
            width="48"
            height="40%"
            x="125"
            y="50%"
            fill="#107F43"
            opacity=".05"
            rx="24"
          />
          <rect
            width="48"
            height="60%"
            x="148"
            y="45%"
            fill="#029FD9"
            opacity=".05"
            rx="24"
          />
          <rect
            width="48"
            height="85%"
            x="185"
            y="26%"
            fill="#DDF94B"
            opacity=".05"
            rx="24"
          />
          <rect
            width="48"
            height="80%"
            x="222"
            y="18%"
            fill="#01EDD1"
            opacity=".05"
            rx="24"
          />
          <rect
            width="48"
            height="63%"
            x="252"
            y="45%"
            fill="#2FB945"
            opacity=".05"
            rx="24"
          />
        </g>
      </svg>
    );
  }
}

import * as React from "react";

export interface ErrorHintProps {
  shown: boolean;
}

export class ErrorHint extends React.Component<ErrorHintProps> {
  static defaultProps = {
    shown: true
  };

  render(): JSX.Element | undefined {
    return this.props.shown ? (
      <span className="error" role="alert">
        {this.props.children}
      </span>
    ) : undefined;
  }
}

/*
  Common constants used across application
 */

export const DOCS_PLATFORM_BASE_URL =
  "https://www.cisco.com/c/en/us/td/docs/cloud-systems-management/crosswork-network-automation/b_cisco-crosswork-cloud-user-guide";

export const Constants = {
  // Component constants
  EMPTY_VALUE: "--",
  // Status constants
  STATUS_ERROR: -1,
  STATUS_SUCCESS: 0,

  // Input Text constants
  MAX_TEXT_TITLE: 64,

  TOAST_MAX: 3,
  TOAST_DURATION_DEFAULT: 5000,
  XFADE_DURATION: 300,

  TOOLTIP_HOVER_DELAY: 300,

  DEBOUNCE_DEFAULT: 350,
  DEBOUNCE_INPUT_TO_REMOTE: 750,
  TYPING_DELAY: 600,
  PROCESSING_DEFAULT: 2000,
  POLLING_INTERVAL_DEFAULT: 60,

  ASN_MAX: 4200000000,
  CHART_COLORS: 6,
  MARKER_RADIUS: 4,
  UINT32MAX: 4294967295,
  DEFAULT_CDG_ASN: 65000,
  FETCH_LIMIT_5000: 5000,
  FETCH_LIMIT_10000: 10000,
  LIMIT_1000: 1000,

  DEFAULT_BUCKETS: 60,
  DEFAULT_BARCHART_BUCKETS: 60,

  NO_PADDING: { top: 0, right: 0, bottom: 0, left: 0 },

  // Time Constants
  SEC_TO_NANOSEC: 1000000000,
  MILSEC_TO_NANOSEC: 1000000,
  SECONDS_DURATION: 1000,
  DAY_DURATION: 24 * 60 * 60 * 1000, // in most cases you probably want to use a library to handle daylight savings etc.!!!
  MINUTE_DURATION: 60 * 1000,
  HOUR_DURATION: 60 * 60 * 1000,
  WEEK_DURATION: 24 * 60 * 60 * 1000 * 7,
  MONTH_DURATION: 30 * 24 * 60 * 60000,
  ASPATHREGEX: /[ \t\r\n;,]+/, // Separator for As Paths
  LOGOUTURL: "/session/logout",
  identityUrl: (platform: string): string | undefined => {
    if (platform) {
      return "/auth/login/" + platform + "?redirect_url=";
    }
    return undefined;
  },
  FILE_TYPE_TXT: "text/plain",
  PINNED: "?pin=0",
  UNKNOWN: "UNKNOWN",
  FILE: "FILE",
  SUBNET: "SUBNET",
  ASN: "ASN",
  ADD: "ADD",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  CHANGE: "CHANGE",
  ACTIVE: "ACTIVE",
  UTC_FORMAT: "DD MMM YY HH:mm:ss [UTC]",
  UTC_FORMAT_TIME: "HH:mm [UTC]",
  BASIC_FORMAT: "l LTS",
  BASIC_FORMAT_TIME: "LT",
  DATE_FORMAT: "L",

  MINUTE_UTC_FORMAT: "DD MMM YY HH:mm [UTC]",
  MINUTE_BASIC_FORMAT: "l LT",
  LONG_DATE_FORMAT: "dddd, MMMM Do YYYY",
  MEDIUM_DATE_FORMAT: "ddd MMM Do YYYY",
  AWS_LANDING_URL:
    "/8AC7B411EE147BD804494F6E30BDDBF5EBE5AF8BFFCE6392A6FBF8AF2F3C046E",
  AWS_REGISTRATION_URL:
    "/621C5D436631DE28EED2378FBA937C0A97DDC936B57C717397CF802B89B669A2",
  AWS_REG_INPROGRESS_URL:
    "/48F2C6769B4021D7340485A2129DF9E999858EAF307C7617257601BF21BF1E49",
  AWS_ERROR_URL:
    "/A84024DCB919E0D4972CBD70E7A8986AB4A2A2DA697C7C3249545E317F0E13F4",
  TRIAL_URL:
    "/99f46c6853c1df62c6772dfc8a47e866f895e0d2e2f6c0e0a7323fc12ddacceb", // mapped with the backend for redirection
  FREE_URL: "/ad95d5fa651ba86d8923fe1238d24a4f1988a752acfe426ac72ac7c04471bc17", // mapped with the backend for redirection

  // Data Service Events
  EVENT_REQUEST_START: "requestStart",
  EVENT_REQUEST_END: "requestEnd",

  // React keystroke names
  ARROW_LEFT: "ArrowLeft",
  ARROW_RIGHT: "ArrowRight",
  TAB: "Tab",
  BACKSPACE: "Backspace",
  INFO: "INFO",
  ERROR: "ERROR",
  WARN: "WARN",
  CSV: ".csv",
  TYPE_JSON: ".json",
  // Default Form Values
  S3_DEFAULT_FQDN: "s3.amazonaws.com",
  S3_DEFAULT_PORT: 443,
  DEVICE_DEFAULT_PORT: 22,
  TELEMETRY_DEFAULT_GNMI_PORT: 57400,
  FLOW_REPLICATION_DEFAULT_PORT: 2055,
  ENTER: "Enter",
  CONFIRM: "Confirm",
  UTC: "UTC",
  ASC: "ASC",
  DESC: "DESC",
  EMAIL: "EMAIL",
  SMS: "SMS",
  S3: "S3",
  HOME: "Home",
  ALARMS: "Alarms",
  TAGS: "Tags",
  BGP_UPDATES: "BGPUpdates",
  GOOGLE_STORAGE_REMOVED_S3_SVC: "REMOVED_SvcAccountKeyBase64",
  RELEASE_NOTES_URL:
    "https://www.cisco.com/c/en/us/td/docs/cloud-systems-management/crosswork-cloud/b-crosswork-cloud-release-notes.html",
  TRUST_RELEASE_NOTES_URL:
    "https://www.cisco.com/c/en/us/td/docs/cloud-systems-management/crosswork-network-automation/b_cisco-crosswork-trust-insights-release-notes.html",
  TRIAL_HELP_URL:
    "https://www.cisco.com/c/en/us/products/cloud-systems-management/crosswork-network-automation/ccni-trial-guide.html",
  API_DOCS_URL: "/apiDoc/CiscoCrossworkCloudAPI",

  HELP_PLATFORM_LICENSING: DOCS_PLATFORM_BASE_URL + "/m_manage-licensing.html",
  HELP_PLATFORM_PURCHASE: DOCS_PLATFORM_BASE_URL + "/m_manage-purchase.html",
  HELP_PLATFORM_USERS: DOCS_PLATFORM_BASE_URL + "/m_manage-users.html",
  HELP_PLATFORM_IMPORT_EXPORT:
    DOCS_PLATFORM_BASE_URL + "/m_import-and-export-configuration-files.html",
  HELP_PLATFORM_ACTIVITY_LOG:
    DOCS_PLATFORM_BASE_URL + "/m_view-a-list-of-actions-performed.html",
  HELP_PLATFORM_HELP:
    "https://www.cisco.com/c/en/us/td/docs/cloud-systems-management/crosswork-cloud/Docs-Landing-Page/CrossworkCloud_Info_Center.html",
  HELP_EXT_ROUTING_OVERVIEW:
    DOCS_PLATFORM_BASE_URL + "/m_about-external-routing-analytics.html",
  HELP_EXT_ROUTING_ALARMS:
    DOCS_PLATFORM_BASE_URL +
    "/m_monitor-alarms-external-routing-analytics.html#id_92402",
  HELP_EXT_ROUTING_ALARMS_ROOT:
    DOCS_PLATFORM_BASE_URL +
    "/m_monitor-alarms-external-routing-analytics.html",
  HELP_EXT_ROUTING_MONITOR_ASNS:
    DOCS_PLATFORM_BASE_URL + "/m_monitor-asns.html#id_125408",
  HELP_EXT_ROUTING_MONITOR_PREFIXES:
    DOCS_PLATFORM_BASE_URL + "/m_monitor-prefixes.html#id_124883",
  HELP_EXT_ROUTING_BGP_UPDATES:
    DOCS_PLATFORM_BASE_URL +
    "/m_monitor-bgp-updates-external-routing-analytics.html",
  HELP_EXT_ROUTING_CONFIGURE_PREFIXES:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-prefixes-external-routing-analytics.html#id_84722",
  HELP_EXT_ROUTING_CONFIGURE_ASNS:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-asns-external-routing-analytics.html#id_93443",
  HELP_EXT_ROUTING_CONFIGURE_PEERS:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-peers-external-routing-analytics.html#Cisco_Task_in_List_GUI.dita_8fbcc13f-5039-4595-9e6e-590c2990cdf2",
  HELP_EXT_ROUTING_REPORTS:
    DOCS_PLATFORM_BASE_URL + "/m_configure-reports.html",
  HELP_EXT_ROUTING_CONFIGURE_POLICIES:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-policies-external-routing-analytics.html#id_114836",
  HELP_EXT_ROUTING_ENDPOINTS:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-notification-endpoints-external-routing-analytics.html",
  HELP_EXT_ROUTING_EXPRESS: DOCS_PLATFORM_BASE_URL + "/m_use-setup-tools.html",
  HELP_EXT_ROUTING:
    DOCS_PLATFORM_BASE_URL + "/b_cisco-crosswork-cloud-user-guide.html",
  HELP_EXT_ROUTING_ROV:
    DOCS_PLATFORM_BASE_URL + "/m-validate-route-origin-information.html",
  HELP_EXT_ROUTING_TOPOLOGY:
    DOCS_PLATFORM_BASE_URL + "/m_view-prefix-path-topology.html",

  HELP_TRAFFIC_OVERVIEW:
    DOCS_PLATFORM_BASE_URL + "/m_about-traffic-analysis.html",
  HELP_TRAFFIC_MONITOR_DEVICES:
    DOCS_PLATFORM_BASE_URL + "/m_monitor-devices.html",
  HELP_TRAFFIC_MONITOR_INTERFACES:
    DOCS_PLATFORM_BASE_URL + "/m_monitor-interfaces-traffic-analysis.html",
  HELP_TRAFFIC_MONITOR_PREFIXES:
    DOCS_PLATFORM_BASE_URL + "/m_monitor-prefixes.html#id_124883",
  HELP_TRAFFIC_MONITOR_ASNS:
    DOCS_PLATFORM_BASE_URL + "/m_monitor-asns.html#id_125408",
  HELP_TRAFFIC_TOOLS_COMPARISON:
    DOCS_PLATFORM_BASE_URL + "/m_compare-traffic.html",
  HELP_TRAFFIC_TOOLS_PROSPECTING:
    DOCS_PLATFORM_BASE_URL + "/m_peer-prospecting.html",
  HELP_TRAFFIC_CONFIGURE_DEVICES:
    DOCS_PLATFORM_BASE_URL + "/m_configure-devices.html",
  HELP_TRAFFIC_CONFIGURE_GATEWAYS:
    DOCS_PLATFORM_BASE_URL + "/m_configure-crosswork-data-gateways.html",
  HELP_TRAFFIC_CONFIGURE_CREDENTIALS:
    DOCS_PLATFORM_BASE_URL + "/m_configure-credentials.html",
  HELP_TRAFFIC_CONFIGURE_DEVICES_GROUPS:
    DOCS_PLATFORM_BASE_URL + "/m_configure-device-groups.html",
  HELP_TRAFFIC_REMOVED_DEVICES:
    DOCS_PLATFORM_BASE_URL + "/m_configure-devices.html",
  HELP_TRAFFIC_SETUP_CHECKLIST:
    DOCS_PLATFORM_BASE_URL + "/m_use-setup-tools.html",
  HELP_TRAFFIC:
    DOCS_PLATFORM_BASE_URL + "/b_cisco-crosswork-cloud-user-guide.html",
  HELP_TRAFFIC_FLOW_REPLICATION:
    DOCS_PLATFORM_BASE_URL +
    "/m_send-netflow-traffic-to-multiple-destinations.html",
  HELP_TRAFFIC_RECOMMENDATIONS:
    DOCS_PLATFORM_BASE_URL + "/m_optimize_interface_utilization.html",

  HELP_TRUST_OVERVIEW: DOCS_PLATFORM_BASE_URL + "/m_about-trust-insights.html",
  HELP_FLOW_ALARM_POLICIES:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-policies-external-routing-analytics.html",
  HELP_FLOW_ALARM_POLICY_CREATE:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-policies-external-routing-analytics.html#task_zr5_rls_dyb",
  HELP_FLOW_ALARM_POLICY_DETAILS:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-policies-external-routing-analytics.html#task_vkn_1vt_dyb",
  HELP_FLOW_ALARM_POLICY_LIST:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-policies-external-routing-analytics.html#crosswork-cloud-traffic-analysis-policies",
  HELP_FLOW_ALARMS:
    DOCS_PLATFORM_BASE_URL +
    "/m_monitor-alarms-external-routing-analytics.html",
  HELP_FLOW_ALARMS_DETAILS:
    DOCS_PLATFORM_BASE_URL + "/m-alarm-descriptions.html",
  HELP_TRUST_ALARMS:
    DOCS_PLATFORM_BASE_URL +
    "/m_monitor-alarms-external-routing-analytics.html#concept_brw_pjv_dyb",
  HELP_TRUST_ALARMS_DETAILS:
    DOCS_PLATFORM_BASE_URL + "/m-alarm-descriptions.html",
  HELP_TRUST_ALARM_POLICY_DETAILS:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-policies-external-routing-analytics.html#task_policies_manage_trust",
  HELP_TRUST_MONITOR_DEVICES:
    DOCS_PLATFORM_BASE_URL + "/m_monitor-devices.html",
  HELP_TRUST_TOOLS_DEVICE_COMPARISON:
    DOCS_PLATFORM_BASE_URL + "/m_compare-devices.html",
  HELP_TRUST_TOOLS_FIND_PACKAGE:
    DOCS_PLATFORM_BASE_URL + "/m_find-packages.html",
  HELP_TRUST_TOOLS_FIND_HARDWARE:
    DOCS_PLATFORM_BASE_URL + "/m_find-hardware.html",
  HELP_TRUST_TOOLS_FIND_FILES: DOCS_PLATFORM_BASE_URL + "/m_find-files.html",
  HELP_TRUST_CONFIGURE_DEVICES:
    DOCS_PLATFORM_BASE_URL + "/m_configure-devices.html",
  HELP_TRUST_CONFIGURE_GATEWAYS:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-crosswork-data-gateways.html#preflight-checklist2",
  HELP_TRUST_CONFIGURE_ADD_GATEWAYS:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-crosswork-data-gateways.html#task_zz4_g1l_tzb",
  HELP_TRUST_CONFIGURE_ADD_GATEWAYS_STEPS:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-crosswork-data-gateways.html#Cisco_Task_in_List_GUI.dita_9eb946cc-9297-4d30-93a8-471b9cd81ffc",
  HELP_TRUST_CONFIGURE_CREDENTIALS:
    DOCS_PLATFORM_BASE_URL + "/m_configure-credentials.html",
  HELP_TRUST_CONFIGURE_KGFS:
    DOCS_PLATFORM_BASE_URL + "/m_configure-known-good-files.html",
  HELP_TRUST_CONFIGURE_POLICIES:
    DOCS_PLATFORM_BASE_URL +
    "/m_configure-policies-external-routing-analytics.html#crosswork-cloud-trust-policies",
  HELP_TRUST_REMOVED_DEVICES:
    DOCS_PLATFORM_BASE_URL + "/m_configure-devices.html",
  HELP_TRUST:
    DOCS_PLATFORM_BASE_URL + "/b_cisco-crosswork-cloud-user-guide.html",

  HELP_STOREFRONT_PAYMENT_URL:
    DOCS_PLATFORM_BASE_URL + "/m_purchasing-support.html",
  HELP_SUBSCRIPTION_URL:
    DOCS_PLATFORM_BASE_URL + "/m_subscription-plan-options.html",
  HELP_SALES_URL:
    DOCS_PLATFORM_BASE_URL +
    "/m_purchase-crosswork-cloud.html#Cisco_Task_in_List_GUI.dita_3b9144c3-51c9-4538-9e49-a97038d973e9",
  HELP_AWS_URL:
    DOCS_PLATFORM_BASE_URL +
    "/m_purchase-crosswork-cloud.html#Cisco_Task_in_List_GUI.dita_004bd7fe-26da-4d00-bea8-46f5bd5aa773",

  PREFIXES_KEY: "prefixes",
  ASNS_KEY: "asns",
  POLICIES_KEY: "policies",
  ENDPOINTS_KEY: "notificationEndpoints",
  STEP1: "1",
  STEP2: "2",
  STEP3: "3",
  STEP4: "4",
  GO_TO_REPORTS_PAGE: "/reports",
  LINE_SEPERATOR: "line_seperator",
  NOTFN_TAG_SUFFIX: "_out",
  EXPRESS_PREFIX: "Express_",
  SUBSCRIPTION_OPTIONS:
    "https://www.cisco.com/c/en/us/td/docs/cloud-systems-management/crosswork-network-automation/b_cisco-crosswork-cloud-user-guide/m_subscription-plan-options.html",
  EXTERNALROUTING_VIDEO:
    "https://www.cisco.com/c/en/us/products/cloud-systems-management/crosswork-network-automation/index.html?socialshare=NetworkInsights",
  TRAFFICANALYSIS_VIDEO:
    "https://www.cisco.com/c/en/us/products/cloud-systems-management/crosswork-network-automation/index.html?socialshare=TrafficAnalysis",
  TRUSTINSIGHTS_VIDEO:
    "https://www.cisco.com/c/en/us/products/cloud-systems-management/crosswork-network-automation/index.html?socialshare=TrustInsights",
  EXTERNALROUTING_DATASHEET:
    "https://www.cisco.com/c/en/us/products/collateral/cloud-systems-management/crosswork-network-automation/datasheet-c78-740228.html",
  TRAFFICANALYSIS_DATASHEET:
    "https://www.cisco.com/c/en/us/products/collateral/cloud-systems-management/crosswork-network-automation/datasheet-c78-744707.html",
  TRUSTINSIGHTS_DATASHEET:
    "https://www.cisco.com/c/en/us/products/collateral/cloud-systems-management/crosswork-network-automation/datasheet-c78-741972.html",
  BGPMON_SIGNIN: "https://portal.bgpmon.net/login.php?ret=/",
  PEERMON_GUIDE:
    "https://www.cisco.com/c/en/us/products/collateral/cloud-systems-management/crosswork-network-automation/ccni-peermon-guide.html",
  CDG_LATEST_RELEASE: "https://software.cisco.com/download/home/286323753/type",
  XWC_REQUEST_TRIAL: "https://engage2demand.cisco.com/LP=20037?",
  XWC_PRODUCTS:
    "https://www.cisco.com/c/en/us/products/cloud-systems-management/crosswork-network-automation/index.html#cloud-products",
  XWC_SUPPORT:
    "https://www.cisco.com/c/en/us/td/docs/cloud-systems-management/crosswork-network-automation/b_cisco-crosswork-cloud-user-guide/m_get-product-support.html",
  XWC_COMMUNITY:
    "https://community.cisco.com/t5/custom/page/page-id/search?filter=location:4441-docs-service-providers&q=Crosswork%20Network%20Insights&mode=board",
  XWC_COMMUNITY_JOIN:
    "https://community.cisco.com/t5/custom/page/page-id/search?q=crosswork%20cloud%20api&mode=",
  XWC_PRESALES: "https://engage2demand.cisco.com/lp=11069?ecid=13896",
  XWC_FINDRESELLER:
    "https://locatr.cloudapps.cisco.com/WWChannels/LOCATR/openBasicSearch.do",
  XWC_OUTSIDE_USA: "https://globalcontacts.cloudapps.cisco.com/contacts",
  XWC_CALL_NUMBER: "tel:+1-800-553-6387",
  XWC_CONTACTS: "https://globalcontacts.cloudapps.cisco.com/contacts",
  XWC_FEEDBACK: "https://secure.opinionlab.com/ccc01/o.asp?id=jBjOhqOJ",
  XWC_HELP:
    "https://www.cisco.com/c/en/us/td/docs/cloud-systems-management/crosswork-network-automation/b_cisco-crosswork-cloud-user-guide/m_about-external-routing-analytics.html",
  XWC_SITEMAP: "https://www.cisco.com/c/en/us/about/sitemap.html",
  XWC_TERMS_CONDITIONS:
    "https://www.cisco.com/c/en/us/about/legal/terms-conditions.html",
  XWC_PRIVACY_STATEMENT:
    "https://www.cisco.com/c/en/us/about/legal/privacy-full.html",
  XWC_COOKIES:
    "https://www.cisco.com/c/en/us/about/legal/privacy-full.html#cookies",
  XWC_TRADEMARKS: "https://www.cisco.com/c/en/us/about/legal/trademarks.html",
  XWC_SUPPORT_CASE: "https://mycase.cloudapps.cisco.com/case",
  XWC_TERMS:
    "https://www.cisco.com/c/dam/en_us/about/doing_business/legal/OfferDescriptions/crosswork-cloud.pdf",
  INSIGHTS_COMPARE_PKG_LIMIT: 500,
  LIMIT_50: 50,
  LIMIT_500: 500,

  KB: 1024,
  MB: Math.pow(1024, 2),
  GB: Math.pow(1024, 3),
  TB: Math.pow(1024, 4),
  PB: Math.pow(1024, 5),
  EB: Math.pow(1024, 6),
  KBIT: 1000,
  MBIT: Math.pow(1000, 2),
  GBIT: Math.pow(1000, 3),
  TBIT: Math.pow(1000, 4),
  PBIT: Math.pow(1000, 5),
  EBIT: Math.pow(1000, 6),

  MAX_PREFIX_SUBSCIPTIONS_ALLOWED: 5000,

  XW_CLOUD_SALES_EMAIL: "sales@crosswork.cisco.com",
  XW_CLOUD_ASK_ASSISTANCE_EMAIL: "ask@crosswork.cisco.com",
  XW_CLOUD_SUPPORT_EMAIL: "support@crosswork.cisco.com",

  // Backend strings NOT ENUMS
  MAGIC_INTERFACE_FOR_CDG_IP: "eth0",
  MAGIC_INTERFACE_FOR_CDG_IP_SB: "eth1",
  MAGIC_INTERFACE_FOR_CDG_IP_NB: "eth2",
  STYX: "STYX",
  CHARON: "CHARON",
  FLOWD: "FLOWD",

  AWS_REFERRER: "source=aws",

  TAGS_PAGE_LIMIT: 1000,
  MAX_TAGS_PER_ITEM: 30,
  MAX_TAG_LENGTH: 64,
  FLOW_QUERY_TIME_OFFSET: 5,

  CONTAINER_TRUST: "CONTAINER_TRUST",
  CONTAINER_FLOW: "CONTAINER_FLOW",

  DEFAULT_DEVICE_GROUP: "unassigned",

  STALENESS_NO_CACHE: 0.001,
  UTILIZATION_THRESHOLD: { HIGH: 0.5, CRITICAL: 0.8 },

  // XR versions
  XR7_2_2: "7.2.2",
  XR7_3_1: "7.3.1",
  XR7_3_2: "7.3.2",
  XR7_4_1: "7.4.1",
  XR7_4_2: "7.4.2",
  XR7_5_1: "7.5.1",
  XR7_5_2: "7.5.2",
  XR7_7_2: "7.7.2",
  XR7_7_21: "7.7.21",
  // ROV status notes strings to match to highlight
  ORIGIN_ASN_ERROR: "origin does not match",
  MAX_LENGTH_ERROR: "length exceeds"
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ReverseMapping<T extends Record<keyof T, keyof any>> = {
  [K in T[keyof T]]: { [P in keyof T]: K extends T[P] ? P : never }[keyof T];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reverseMapping<T extends Record<keyof T, keyof any>>(
  t: T
): ReverseMapping<T> {
  const ret = {} as ReverseMapping<T>;
  (Object.keys(t) as Array<keyof T>).forEach(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (k: keyof T): void => (ret[t[k]] = k as any)
  );
  return ret;
}

export function titleCase(s: string): string {
  return s
    .replace(/[^a-z0-9]/gi, " ")
    .toLowerCase()
    .split(" ")
    .map((token: string): string => {
      return token.charAt(0).toUpperCase() + token.slice(1);
    })
    .join(" ");
}

export function replaceChar(s: string, c: string, v: string = ""): string {
  return s.replace(c, v);
}

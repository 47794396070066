import * as React from "react";
import { ToastMessage, ToastType } from "../../services/toastService";
import { label } from "../../core/global";
import { Constants } from "../../core/constants";

import "./toast.scss";
import { Icon } from "../icon/icon";

const typeMap = new Map<ToastType, string>();
typeMap.set("info", label.component.info);
typeMap.set("success", label.component.success);
typeMap.set("warning", label.component.warning);
typeMap.set("error", label.component.error);

export interface ToastProps {
  toast: ToastMessage;
  onClose: () => void;
}

export class Toast extends React.Component<ToastProps> {
  private barSpentRef: React.RefObject<HTMLDivElement>;
  private closeTimeout?: NodeJS.Timeout;

  constructor(props: ToastProps) {
    super(props);
    this.barSpentRef = React.createRef<HTMLDivElement>();
  }

  get closeable(): boolean {
    // default to closeable
    return this.props.toast.closeable === undefined
      ? true
      : this.props.toast.closeable;
  }
  get duration(): number {
    return this.props.toast.duration !== undefined
      ? this.props.toast.duration
      : Constants.TOAST_DURATION_DEFAULT;
  }

  componentDidMount(): void {
    if (this.barSpentRef.current && this.duration > 0) {
      // Close after specified duration
      this.closeTimeout = setTimeout((): void => {
        this.closeTimeout = undefined;
        this.props.onClose();
      }, this.duration);

      // CSP forces this to be programmatic
      if (this.barSpentRef.current) {
        this.barSpentRef.current.style.animationDuration = this.duration + "ms";
      }
    }
  }

  componentWillUnmount(): void {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
  }

  handleCloseClick = (): void => {
    this.closeTimeout = undefined;
    this.props.onClose();
  };

  handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === " " || e.key === "Enter" || e.key === "Escape") {
      this.handleCloseClick();
      e.stopPropagation();
    }
  };

  render(): JSX.Element {
    const { toast } = this.props;
    const duration = toast.duration || 5000;
    const title = toast.title || typeMap.get(toast.type) || undefined;
    const ariaRole =
      toast.type === "error" || toast.type === "warning" ? "alert" : "status";
    return (
      <div
        data-qa="toaster"
        className={"xw-toast type-" + toast.type}
        role={ariaRole}
        aria-live={ariaRole === "alert" ? "assertive" : "polite"}
        tabIndex={0}
        onKeyDown={this.handleKeyDown}
      >
        {title && <div className="title">{title}</div>}
        <div className="message">{toast.message}</div>
        {this.closeable && (
          <button
            type="button"
            aria-label={label.closeNotification}
            className="close-button"
            onClick={this.handleCloseClick}
          >
            <Icon name="x" />
          </button>
        )}
        <div className="bar">
          {duration > 0 && <div className="spent" ref={this.barSpentRef} />}
        </div>
      </div>
    );
  }
}

import {
  DataLookbackRuleType,
  DataLookbackRuleTypeMap,
  InventoryReportingRuleType,
  InventoryReportingRuleTypeMap,
  ReportingRuleType,
  ReportingRuleTypeMap,
  SubscriptionRuleType,
  SubscriptionRuleTypeMap,
  SubscriptionTierMap
} from "generated-proto-files/ccsubs_gen_pb";
import { SubscriptionType } from "generated-proto-files/subscriptions_pb";

import { unreachable } from "../../../utils/switch";
import { label } from "../../../core/global";
import { PlanCategories } from "./commonTierRules";

export const trustPlanCategory = [
  "subscription",
  "lookback",
  "alarms",
  "reporting",
  "inventory",
  "sso"
] as const;

export const getCurrentTrustPlan = ():
  | SubscriptionTierMap[keyof SubscriptionTierMap]
  | undefined => {
  return window.xw.tierInfo?.featureTierInfo?.getCctiRules()?.getFeatureTier();
};

export const isCCTITrial = (): boolean => {
  return (
    window.xw.tierInfo?.features?.getTrustInsightsType() ===
    SubscriptionType.TYPE_TRIAL
  );
};

export const getTrustCategoriesLabel = (categories: PlanCategories): string => {
  const trustCategories = categories as typeof trustPlanCategory[number];
  switch (trustCategories) {
    case "subscription":
      return label.subscription;
    case "lookback":
      return label.dataLookBack;
    case "inventory":
      return label.inventoryReporting;
    case "reporting":
      return label.trustReporting;
    case "sso":
      return label.singleSignOn;
    case "alarms":
      return label.trustAlarms;
    default:
      unreachable(trustCategories);
      return label.null;
  }
};

export const inventoryRuleLabelDescription = (
  inventoryRuleMap: InventoryReportingRuleTypeMap[keyof InventoryReportingRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (inventoryRuleMap) {
    case InventoryReportingRuleType.TRUST_INVENTORY_REPORTING_SYSTEM_CHANGE_HISTORY:
      return {
        label: label.systemChangeHistory,
        description: label.systemChangeHistoryDescription
      };
    case InventoryReportingRuleType.TRUST_INVENTORY_REPORTING_IMMUTABLE_EVIDENCE_CHAIN:
      return {
        label: label.immutableEvidenceChain,
        description: label.immutableEvidenceChainDescription
      };
    case InventoryReportingRuleType.TRUST_INVENTORY_REPORTING_DEVICE_SOFTWARE_COMPARISON:
      return {
        label: label.deviceSoftwareComparison,
        description: label.deviceSoftwareComparisonDescription
      };
    case InventoryReportingRuleType.TRUST_INVENTORY_REPORTING_DEVICE_HARDWARE_COMPARISON:
      return {
        label: label.deviceHardwareComparison,
        description: label.deviceHardwareComparisonDescription
      };
    case InventoryReportingRuleType.INVALID_TRUST_INVENTORY_REPORTING_RULE_TYPE:
      return undefined;
    default:
      unreachable(inventoryRuleMap);
      return undefined;
  }
};

export const trustReportingRuleLabelDescription = (
  trustRuleMap: ReportingRuleTypeMap[keyof ReportingRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (trustRuleMap) {
    case ReportingRuleType.TRUST_REPORTING_HARDWARE_INTEGRITY_VALIDATION:
      return {
        label: label.hardwareIntegrityValidation,
        description: label.hardwareValidationDescription
      };
    case ReportingRuleType.TRUST_REPORTING_SOFTWARE_INTEGRITY_VALIDATION:
      return {
        label: label.softwareValidation,
        description: label.softwareValidationDescription
      };
    case ReportingRuleType.TRUST_REPORTING_PROCESS_INTEGRITY_VALIDATION:
      return {
        label: label.processIntegrityValidation,
        description: label.processIntegrityValidationDescription
      };
    case ReportingRuleType.TRUST_REPORTING_XR_PACKAGE_SIGNATURE:
      return {
        label: label.xrPackageSignatureSupport,
        description: label.xrPackageSignatureSupportDescription
      };
    case ReportingRuleType.TRUST_REPORTING_XR_ENHANCED_FILE_SYSTEM_INTEGRITY:
      return {
        label: label.xrEnhancedFileSystemIntegrityReporting,
        description: label.xrEnhancedFileSystemIntegrityReportingDescription
      };
    case ReportingRuleType.INVALID_TRUST_REPORTING_RULE_TYPE:
      return undefined;
    default:
      unreachable(trustRuleMap);
      return undefined;
  }
};

export const trustSubscriptionLabelDescription = (
  subscriptionsRuleMap: SubscriptionRuleTypeMap[keyof SubscriptionRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (subscriptionsRuleMap) {
    case SubscriptionRuleType.SUBSCRIPTION_TERM:
      return {
        label: label.subscriptionTiers,
        description: label.cctiSubscriptionTermDescription
      };
    case SubscriptionRuleType.INVALID_SUBSCRIPTION_RULE_TYPE:
      return undefined;
    default:
      unreachable(subscriptionsRuleMap);
      return undefined;
  }
};

export const dataRetentionLabelDescription = (
  dataLookbackRuleMap: DataLookbackRuleTypeMap[keyof DataLookbackRuleTypeMap]
): { label: string; description: string } | undefined => {
  switch (dataLookbackRuleMap) {
    case DataLookbackRuleType.DATA_RETENTION_AND_LOOKBACK:
      return {
        label: label.dataRetentionLookback,
        description: label.dataRetentionLookbackDescription
      };
    case DataLookbackRuleType.INVALID_DATA_LOOKBACK_RULE_TYPE:
      return undefined;
    default:
      unreachable(dataLookbackRuleMap);
      return undefined;
  }
};

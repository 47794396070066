import { Constants } from "../core/constants";

const debounce = (
  fn: (...args: any[]) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
  wait = Constants.DEBOUNCE_DEFAULT,
  immediate = false
): typeof fn => {
  let timeout: number | undefined;
  return function executedFunction(this: unknown, ...args): void {
    const later = (): void => {
      timeout = undefined;
      if (!immediate) {
        fn.apply(this, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
    if (callNow) {
      fn.apply(this, args);
    }
  };
};

const throttle = (
  fn: (...args: any[]) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
  delay: number
): typeof fn => {
  let lastCall = 0;
  return (...args): void => {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    fn(...args);
  };
};

export { debounce, throttle };

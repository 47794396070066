// Was getting error only one babel-polyfill is allowed, when logging out via new flow.
// import "babel-polyfill";

import { grpc } from "grpc-web-client";
import { logger } from "./global";

// TODO: this needs to be deleted, but linking to apiService causes issues, too
class ApiError {
  grpcResp?: grpc.UnaryOutput<grpc.ProtobufMessage>;
  jsonResp?: Response;

  setGrpcError(grpcResp: grpc.UnaryOutput<grpc.ProtobufMessage>): void {
    this.grpcResp = grpcResp;
    logger.error(this.getMessage());
  }

  setJsonError(jsonResp: Response): void {
    this.jsonResp = jsonResp;
    logger.error(this.getMessage());
  }

  getMessage(): string {
    if (this.grpcResp && this.grpcResp.statusMessage) {
      return this.grpcResp.statusMessage;
    } else if (this.jsonResp && this.jsonResp.statusText) {
      return this.jsonResp.statusText;
    }
    return "Error";
  }
}

const createRequestHeadersWithBody = (
  method: string,
  body: string | undefined
): RequestInit => {
  return {
    credentials: "include" as RequestCredentials, // I don't know why this is required
    method,
    body: JSON.stringify(body)
  };
};

const baseFetch = async (
  method: string,
  url: string,
  body: string,
  jsonReponse = true
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Response | any> => {
  const response = await fetch(url, createRequestHeadersWithBody(method, body));
  if (response.ok) {
    return jsonReponse ? response.json() : response;
  } else {
    const err = new ApiError();
    err.setJsonError(response);
    return Promise.reject(err);
  }
};

const put = baseFetch.bind(undefined, "PUT");
const post = baseFetch.bind(undefined, "POST");
const get = baseFetch.bind(undefined, "GET");

export { get, put, post };

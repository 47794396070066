export interface CachePageType {
  key: string;
  created: number;
  lastAccessed: number;
  lastUpdated: number;
  content: unknown;
}

export default class Cache {
  private pages: Map<string, CachePageType>;
  private maxSize: number;
  constructor() {
    this.pages = new Map();
    this.maxSize = 1000;
  }

  public clear(): void {
    this.pages = new Map();
    return;
  }

  public getPage(key: string): CachePageType | undefined {
    const page = this.pages.get(key);
    if (page) {
      page.lastAccessed = Date.now();
      return page;
    }
    return undefined;
  }

  public getContent(key: string): unknown | undefined {
    const page = this.getPage(key);
    if (page) {
      return page.content;
    }
    return undefined;
  }

  private findLRU(): CachePageType | undefined {
    let lruPage = undefined;
    let lruMs = Number.MAX_SAFE_INTEGER;
    this.pages.forEach((page: CachePageType): void => {
      if (page.lastAccessed < lruMs) {
        lruPage = page;
        lruMs = page.lastAccessed;
      }
    });
    return lruPage;
  }

  public setContent(key: string, content: unknown): void {
    let page = this.pages.get(key);
    if (!page) {
      page = {
        key: key,
        created: Date.now(),
        lastAccessed: Date.now(),
        lastUpdated: Date.now(),
        content: content
      };
      this.pages.set(key, page);
      if (this.pages.size > this.maxSize) {
        const lru = this.findLRU();
        if (lru) {
          // could be worthwhile to set to null instead
          // this is probably fine
          this.pages.delete(lru.key);
        }
      }
    } else {
      page.key = key;
      page.lastAccessed = Date.now();
      page.lastUpdated = Date.now();
      page.content = content;
      this.pages.set(key, page);
    }
    return;
  }

  /* eslint-disable no-console */
  print(): void {
    if (!this.pages.size) {
      console.log("Cache empty");
    } else {
      console.clear();
      console.log("Cache pages (" + this.pages.size + "):");

      const printTime = (ts: number): void => {
        return console.log("\t" + new Date(ts) + " - " + ts);
      };

      [...this.pages.values()].forEach((page): void => {
        console.log("###");
        console.log("key:");
        console.log("\t" + page.key);

        console.log("created:");
        console.log("\t" + printTime(page.created));

        console.log("lastAccessed:");
        console.log("\t" + printTime(page.lastAccessed));

        console.log("lastUpdated:");
        console.log("\t" + printTime(page.lastUpdated));

        console.log("content:");
        // print grpc objects correctly
        // @ts-ignore
        if (page.content.toObject) {
          // @ts-ignore
          console.log(page.content.toObject());
        } else {
          console.log(page.content);
        }
      });
    }
  }
  /* eslint-enable no-console*/
}

import EventEmitter from "eventemitter3";
import { ApiError } from "../core/dataflow/apiService";

export type ToastType = "success" | "info" | "warning" | "error" | undefined;

export interface ToastMessage {
  type?: ToastType;
  message: string;
  title?: string;
  duration?: number;
  closeable?: boolean;
  error?: Error | ApiError;
}

export const QUEUE_EVENT = "queue";
export const DEQUEUE_EVENT = "dequeue";

const toastQueue: ToastMessage[] = [];
export const events = new EventEmitter();

export function queue(message: ToastMessage): void {
  toastQueue.push(message);
  events.emit(QUEUE_EVENT);
}

export function stickyQueue(message: ToastMessage): void {
  message.duration = 0;
  message.closeable = true;
  toastQueue.push(message);
  events.emit(QUEUE_EVENT);
}

export function queueLength(): number {
  return toastQueue.length;
}

export function dequeue(): ToastMessage {
  if (toastQueue.length <= 0) {
    throw new Error("Queue empty");
  }
  const popped = toastQueue.shift();
  if (popped === undefined) {
    throw new Error("Dequeued entry is undefined");
  }
  return popped;
}

import * as logLevel from "loglevel";
import messages from "../resources/messages";

// typesafe message lookup
export const label = messages["en-US"];

// to make logging easier
export const logger = logLevel;

export const labelParam = (
  template: string,
  keyValue: { [key: string]: string | number }
): string => {
  const pattern = /{(.*?)}/g;
  return template.replace(pattern, (match, token): string => {
    if (typeof keyValue[token] === "number") {
      return keyValue[token].toString();
    } else {
      return keyValue[token] as string;
    }
  });
};

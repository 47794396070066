import * as React from "react";
import "./fullPage.scss";

import { AnimatedLines } from "../../components/animatedLines/animatedLines";
import { Icon } from "../../components/icon/icon";
import { label, labelParam } from "../../core/global";
import { Toaster } from "../../components/toast/toaster";

interface Props {}

interface State {}

export default class Maintenance extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  static getFooterYearValue(): string {
    const year = new Date().getFullYear();
    return labelParam(label.footer, { year });
  }

  render(): JSX.Element {
    return (
      <div className="full-page">
        <AnimatedLines />
        <div className="gradient-bar" />
        <div className="content">
          <div className="logo">
            <Icon name="cisco-logo" />
          </div>
          <h1 className="title">
            <span className="crosswork">{label.crosswork}</span>
            {label.cloud}
          </h1>
          <p className="message">
            Currently undergoing planned maintenance. We will be back shortly.
          </p>
          <Toaster />
        </div>
        <div className="copyright">{Maintenance.getFooterYearValue()}</div>
      </div>
    );
  }
}

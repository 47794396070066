export function queryFocusableAll(
  element: HTMLElement = document.body
): HTMLElement[] {
  return Array.from(
    element.querySelectorAll(
      "a, button, input, textarea, select, details,[tabindex]"
    )
  ).filter(
    (el) =>
      el instanceof HTMLElement &&
      !el.hasAttribute("disabled") &&
      el.tabIndex !== -1
  ) as HTMLElement[];
}

export function isElementInViewport(
  element: HTMLElement = document.body
): boolean {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
export function focusFirstElement(element: HTMLElement = document.body): void {
  const firstElement = queryFocusableAll(element)[0];
  if (firstElement && isElementInViewport(firstElement)) {
    firstElement.focus();
  }
}

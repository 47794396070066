import { v4 as uuidv4 } from "uuid";

export default uuidv4;

export const emptyUUID = "00000000-0000-0000-0000-000000000001";

export const generatePolicyId = (): string => {
  return "P_" + uuidv4();
};

export const generateEndpointId = (): string => {
  return "E_" + uuidv4();
};

export const generatePeerStatsId = (): string => {
  return "PStats_" + uuidv4();
};

export const generateDCRestartId = (): string => {
  return "DC_Restart_" + uuidv4();
};

export const generateDeviceChangesId = (): string => {
  return "DC_Changes_" + uuidv4();
};

export const generateHwSwChangesPlusCountId = (): string => {
  return "HwSw_Changes_Count" + uuidv4();
};

export const generateHwSwChangesChartId = (): string => {
  return "D_HwSw_" + uuidv4();
};

export const generateDeviceHwSwChangesId = (): string => {
  return "D_HwSw_Changes" + uuidv4();
};

export const generateDeviceDonutId = (): string => {
  return "Device_Donut_" + uuidv4();
};

export const generateDossierErrorId = (): string => {
  return "Dossier_Error_" + uuidv4();
};

export const generateFindPackageChartId = (): string => {
  return "FindPackage_Chart_" + uuidv4();
};

export const generateFindPackageChartRestartsId = (): string => {
  return "FindPackage_Chart_Restarts_" + uuidv4();
};

export const generateSNMPCommunitiesId = (): string => {
  return "SNMP_" + uuidv4();
};

export const generateFlowConfigurationId = (): string => {
  return "FlowConfig_" + uuidv4();
};

export const generateRPSLTableRowId = (): string => {
  return "RPSL_" + uuidv4();
};

export const generateBGPTableRequestId = (): string => {
  return "BGP_Updates_Table_" + uuidv4();
};

export const generateBGPChartSelectedAdvertisementsId = (): string => {
  return "BGP_Chart_Selected_Advertisements_" + uuidv4();
};

export const generateBGPChartSelectedWithdrawalsId = (): string => {
  return "BGP_Chart_Selected_Withdrawals_" + uuidv4();
};

export const generateBGPChartWithdrawalsId = (): string => {
  return "BGP_Chart_Withdrawals_" + uuidv4();
};

export const generateBGPChartAdvertisementsId = (): string => {
  return "BGP_Chart_Advertisements_" + uuidv4();
};
export const generateAlarmHistoryId = (): string => {
  return "Alarm_History_Table_" + uuidv4();
};
export const generateAlarmHistoryTotalsId = (): string => {
  return "Alarm_History_Table_Totals_" + uuidv4();
};

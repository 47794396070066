import PendingRequests from "./pendingRequests";
import Subscriptions from "./subscriptions";
import Cache from "./cache";
import Scheduler from "./scheduler";
import { GlobalStateType, initialGlobalState } from "./globalState";
import { Environment, getEnvName, isProduction } from "../../utils/env";
import logger from "loglevel";
import { currentTimeRange, StandardTimeRangeOption } from "../../utils/date";
import moment from "moment-timezone";
import { TierInfo } from "../../pages/settings/tier/commonTierRules";
import { UserDetails } from "generated-proto-files/auth_user_pb";
import { UserPreferences } from "generated-proto-files/glokta_pb";
import { Constants } from "../constants";
import { MarketplaceResponse } from "generated-proto-files/baku_pb";

// This is suppose to be a wrapper of all things, right now it just serves as a loose window object
export default class AppState {
  showDocs: boolean;
  pendingRequests: PendingRequests;
  subscriptions: Subscriptions;
  cache: Cache;
  scheduler: Scheduler;
  globalState: GlobalStateType;
  permissions: {
    trust: boolean;
    flow: boolean;
  };
  userInfo?: UserDetails;
  tierInfo?: TierInfo;
  preferences?: UserPreferences;
  awsMarketPlace?: MarketplaceResponse;
  displayBits: boolean;
  chartDebug: boolean;
  morePrefixPauseOptions: boolean;
  environmentConfig?: Environment;

  constructor() {
    this.pendingRequests = new PendingRequests();
    this.subscriptions = new Subscriptions();
    this.cache = new Cache();
    this.scheduler = new Scheduler();
    this.permissions = {
      trust: false, // this value gets set in home.tsx before app load, default to false for safety
      flow: false // this value gets set in home.tsx before app load, default to false for safety
    };

    this.displayBits =
      localStorage.getItem("displayBits") !== null
        ? localStorage.getItem("displayBits") === "true"
        : true;

    this.chartDebug =
      localStorage.getItem("chartDebug") !== null
        ? localStorage.getItem("chartDebug") === "true"
        : !isProduction();
    this.showDocs = false;
    this.globalState = initialGlobalState;
    this.morePrefixPauseOptions =
      localStorage.getItem("morePrefixPauseOptions") === "true";
  }

  // Trust
  setForceShowTrust = (): void => {
    logger.error(
      "This feature has been deprecated. Permissions are now being served via ramius.auth_user.GetFeatures"
    );
  };

  getShowTrust = (): boolean => {
    return this.permissions.trust;
  };

  // Flow
  setForceShowFlow = (): void => {
    logger.error(
      "This feature has been deprecated. Permissions are now being served via ramius.auth_user.GetFeatures"
    );
  };

  // default to API value unless local override is set!
  getShowFlow = (): boolean => {
    return this.permissions.flow;
  };

  getAWSMarketPlaceAccess = (): boolean => {
    return !!(
      this.awsMarketPlace?.getEnabled() &&
      this.awsMarketPlace?.getIsAuthorized() &&
      !!this.awsMarketPlace?.getOffersList().length
    );
  };

  // Remote backend
  // override default (dev watch only)
  setForceEnableRemoteBackend = (flag: boolean): void => {
    localStorage.setItem("enableRemoteBackend", "" + flag);
  };

  setForceMaintenance = (flag: boolean): void => {
    localStorage.setItem("forceMaintenance", "" + flag);
  };

  getForceMaintenance = (): boolean | undefined => {
    const localSetting = localStorage.getItem("forceMaintenance");
    if (localSetting === "true") {
      return true;
    } else if (localSetting === "false") {
      return false;
    } else {
      return undefined;
    }
  };

  setDisplayBits(bits: boolean): void {
    localStorage.setItem("displayBits", bits ? "true" : "false");
    this.displayBits = bits;
  }

  setChartDebug(debug: boolean | undefined): void {
    if (debug === undefined) {
      localStorage.removeItem("chartDebug");
      this.chartDebug = !isProduction();
      return;
    }
    localStorage.setItem("chartDebug", debug ? "true" : "false");
    this.chartDebug = debug;
  }

  setMorePausePrefixOptions = (flag: boolean): void => {
    localStorage.setItem("morePrefixPauseOptions", flag ? "true" : "false");
    this.morePrefixPauseOptions = flag;
  };

  getMorePausePrefixOptions = (): boolean => {
    const localSetting = localStorage.getItem("morePrefixPauseOptions");
    if (localSetting === "true") {
      return true;
    }
    return false;
  };

  getForceEnableRemoteBackend = (): boolean | undefined => {
    const localSetting = localStorage.getItem("enableRemoteBackend");
    if (localSetting === "true") {
      return true;
    } else if (localSetting === "false") {
      return false;
    } else {
      return undefined;
    }
  };

  // only enable redirect for ui watcher build (:8080) by default
  getEnableRemoteBackend = (): boolean => {
    const forceEnableRemoteBackend = this.getForceEnableRemoteBackend();
    if (forceEnableRemoteBackend !== undefined) {
      return forceEnableRemoteBackend;
    } else {
      const envName = getEnvName();
      return envName === "dev watch";
    }
  };

  terminateWithPrejudice = (): void =>
    logger.warn("Can't terminate app, router never hooked in.");

  setFlowTimerange = (
    value: StandardTimeRangeOption | [number, number]
  ): void => {
    localStorage.setItem("flowTimerange", JSON.stringify(value));
  };

  getFlowTimerange = (): [number, number] | undefined => {
    const stringValue = localStorage.getItem("flowTimerange");
    const rawValue = stringValue ? JSON.parse(stringValue) : undefined;
    if (rawValue && Array.isArray(rawValue)) {
      // custom time range
      return rawValue as [number, number];
    } else if (typeof rawValue === "number") {
      // standard time range (enum)
      return currentTimeRange(
        rawValue as StandardTimeRangeOption,
        moment()
          .startOf("minute")
          .subtract(Constants.FLOW_QUERY_TIME_OFFSET, "minutes")
      );
    } else {
      // warn if we get here due to a parsing/type issue
      if (rawValue !== undefined) {
        logger.warn(
          "getFlowTimeRange: localStorage value not understood, returning undefined",
          stringValue
        );
      }
      // return default
      return undefined;
    }
  };

  setEnableTracking = (enabled: boolean): void => {
    if (enabled) {
      sessionStorage.setItem("enableTracking", "true");
      logger.warn(
        "%c Enabled only for this active browser session",
        "background: #000; color:#ff4c52"
      );
    } else {
      sessionStorage.removeItem("enableTracking");
    }
  };

  // TEMPORARY

  setEnableUnknownFilesToggle = (enabled: boolean): void => {
    if (enabled) {
      localStorage.setItem("enableUnknownFilesToggle", "true");
    } else {
      localStorage.removeItem("enableUnknownFilesToggle");
    }
  };

  getEnableUnknownFilesToggle = (): boolean => {
    return !!localStorage.getItem("enableUnknownFilesToggle");
  };

  setShowTelemetry = (enabled: boolean): void => {
    if (enabled) {
      localStorage.setItem("xwShowTelemetry", "true");
    } else {
      localStorage.removeItem("xwShowTelemetry");
    }
  };

  getShowTelemetry = (): boolean => {
    // always show telemetry on labs (for demos)
    const envName = getEnvName();
    if (envName === "cw-labs") {
      return true;
    }
    return !!localStorage.getItem("xwShowTelemetry");
  };

  setShowFlowAlarms = (enabled: boolean): void => {
    if (enabled) {
      localStorage.setItem("xwShowFlowAlarms", "true");
    } else {
      localStorage.removeItem("xwShowFlowAlarms");
    }
  };

  getShowFlowAlarms = (): boolean => {
    // always show flow alarms on labs (for demos)
    const envName = getEnvName();
    if (envName === "cw-labs") {
      return true;
    }

    const showFlowAlarms = localStorage.getItem("xwShowFlowAlarms");
    return !!showFlowAlarms || true; // to hide flow alarms flip the switch to false
  };

  setEnableBGPUpdatesASPathFilter = (enabled: boolean): void => {
    if (enabled) {
      localStorage.setItem("enableBGPUpdatesASPathFilter", "true");
    } else {
      localStorage.removeItem("enableBGPUpdatesASPathFilter");
    }
  };

  getEnableBGPUpdatesASPathFilter = (): boolean => {
    return !!localStorage.getItem("enableBGPUpdatesASPathFilter");
  };

  setEnableAlarmsFromNaphula = (enabled: boolean): void => {
    if (enabled) {
      localStorage.setItem("xwEnableAlarmsFromNaphula", "true");
    } else {
      localStorage.removeItem("xwEnableAlarmsFromNaphula");
    }
  };
  // true => Naphula
  // false => Bhasmasura
  getEnableAlarmsFromNaphula = (): boolean => {
    return !!localStorage.getItem("xwEnableAlarmsFromNaphula");
  };

  // END TEMPORARY
}

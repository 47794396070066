import React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./core/app";
import "./styles/styles.scss";
import { Landing } from "./pages/session/landing";
import { getEnvironment } from "./utils/env";
import MatomoTracker from "@datapunt/matomo-tracker-js";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { PageLoader } from "./components/loader/pageLoader";
import { logger } from "./core/global";

const MOUNT_NODE = document.getElementById("root");

interface Props {}
interface State {
  matomo?: MatomoTracker;
  loading: boolean;
}

class Root extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      matomo: undefined,
      loading: true
    };
  }

  componentDidMount(): void {
    getEnvironment()
      .then((env) => {
        if (
          env &&
          env.analytics_enable === "true" &&
          env.analytics_site_id &&
          env.analytics_url
        ) {
          this.setState({
            matomo: createInstance({
              urlBase: env.analytics_url,
              siteId: Number(env.analytics_site_id), // Unique Site Identifier in Matomo
              linkTracking: false, // required for SPA
              srcUrl: "./matomo.js",
              disabled: !(
                !!sessionStorage.getItem("enableTracking") ||
                env.analytics_enable === "true"
              ),
              configurations: {
                enableJSErrorTracking: true // enables JS error logging
              }
            })
          });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        logger.error("error loading config", err);
      });
  }

  render(): React.ReactNode {
    const { matomo, loading } = this.state;
    if (loading) {
      return <PageLoader />;
    }
    const component =
      window.location.href ===
      `${window.location.protocol}//${window.location.host}/` ? (
        <Landing />
      ) : (
        <App />
      );
    return matomo ? (
      <MatomoProvider value={matomo}>{component}</MatomoProvider>
    ) : (
      component
    );
  }
}

const render = (): void => {
  ReactDOM.render(<Root />, MOUNT_NODE);
};

render();

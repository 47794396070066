import React from "react";
import { IconSize } from "./icon";

export interface SVGIconProps {
  name: React.ReactElement;
  classNames?: string;
  size?: IconSize;
}

export class SVGIcon extends React.Component<SVGIconProps> {
  static readonly defaultProps = {
    size: "normal"
  };

  get classes(): string[] {
    let classes = ["xw-icon"];
    if (this.props.size) {
      classes.push(this.props.size);
    }
    if (this.props.classNames) {
      const baseClasses = this.props.classNames.split(" ");
      classes = classes.concat(baseClasses);
    }
    return classes;
  }

  renderClone = (): React.ReactNode => {
    const element = this.props.name;
    return (
      <element.type {...element.props} className={this.classes.join(" ")} />
    );
  };

  render(): React.ReactNode {
    return this.renderClone();
  }
}

import "./pageLoader.scss";

import * as React from "react";
import { ProcessingLoader } from "./processingLoader";
import { label } from "../../core/global";

interface Props {
  text: string;
  delay: number; // miliseconds
}

interface State {
  show: boolean;
}

export class PageLoader extends React.Component<Props, State> {
  private delay?: NodeJS.Timeout;
  static defaultProps = {
    text: label.loading,
    delay: 150
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      show: false
    };

    this.delay = setTimeout((): void => {
      this.setState({ show: true });
    }, this.props.delay);
  }

  componentWillUnmount(): void {
    if (this.delay) {
      clearTimeout(this.delay);
    }
  }

  render(): JSX.Element {
    return (
      <div data-qa="pageLoader" className={"xw-page-loader"}>
        {this.state.show && (
          <div className="loading-wrapper">
            <ProcessingLoader />
            <div className="loading-text">{this.props.text}</div>
          </div>
        )}
      </div>
    );
  }
}

import React, { PropsWithChildren } from "react";
import { logger } from "../../core/global";
import { ValidationResult, isValid, Validatable } from "./validation";
import { focusFirstElement } from "../../utils/dom";

export function isValidForm(form: FormContextState): boolean {
  return (
    Object.keys(form.fields).every((k): boolean => {
      const fieldState = form.fields[k];
      return fieldState && fieldState.isValid;
    }) &&
    Object.keys(form.childForms).every((k): boolean => {
      return isValidForm(form.childForms[k]);
    })
  );
}

// gets an array of all fields, including those inside child forms, from a FormContext.
export function allFields(form: FormContextState): FieldState[] {
  let result: FieldState[] = [];
  for (const key in form.fields) {
    result.push(form.fields[key]);
  }
  for (const formName in form.childForms) {
    result = result.concat(allFields(form.childForms[formName]));
  }
  return result;
}

export interface FormProps {
  name: string;
  classNames?: string | string[];
  // IMPORTANT: Only fired on root-level forms. Sub-forms can not submit on their
  // own (nested forms aren't real to the W3C.) Use a standard button onClick if you have to submit a sub-form only.
  onSubmit?: (
    e: React.FormEvent,
    formState: FormContextState,
    isValid: boolean
  ) => void;
  // fired whenever fields or child forms are validated, and the validation state may have changed.
  onValidated?: (isValid: boolean, form: FormContextState) => void;
  focusFirstElement?: boolean;
  ariaLabel?: string;
}

export interface FieldState {
  name: string;
  label?: string;
  // value is here mostly for ease of debugging and could be anything. form don't care
  value?: unknown;
  validationResults: ValidationResult[];
  isValid: boolean;
  dirty: boolean;
  hint?: string;
  ref: Validatable;
}

export interface FormContextState {
  dirty: boolean;
  valid: boolean;
  submitted: boolean;
  parent?: FormContextState;
  fields: { [name: string]: FieldState };
  childForms: { [name: string]: FormContextState };
  onValidate: (
    fieldName: string,
    value: unknown,
    result: ValidationResult[]
  ) => void;
  onChildFormChanged: (name: string, form?: FormContextState) => void;
  onFieldMount: (field: Validatable) => void;
  onFieldUnmount: (field: Validatable) => void;
  onResetDirty: (dirty?: boolean) => void;
}

export const nullFormContext = {
  fields: {},
  childForms: {},
  onValidate: (): void => {},
  onChildFormChanged: (): void => {},
  onFieldMount: (): void => {},
  onFieldUnmount: (): void => {},
  onResetDirty: (): void => {},
  valid: false,
  dirty: false,
  submitted: false
};

export const FormContext =
  React.createContext<FormContextState>(nullFormContext);

interface State extends FormContextState {}

export class Form extends React.Component<PropsWithChildren<FormProps>, State> {
  static contextType = FormContext;
  static defaultProps = {
    focusFirstElement: true
  };

  private formRootRef = React.createRef<HTMLFormElement>();

  constructor(props: FormProps) {
    super(props);
    this.state = {
      dirty: false,
      valid: true,
      submitted: false,
      parent: this.context,
      fields: {},
      childForms: {},
      onValidate: this.handleFieldValidate,
      onChildFormChanged: this.handleChildFormChanged,
      onFieldMount: this.handleFieldMount,
      onFieldUnmount: this.handleFieldUnmount,
      onResetDirty: this.resetDirty
    };
  }

  componentDidMount(): void {
    this.propagateUpdate();
    // auto-focus the first element in form
    if (this.props.focusFirstElement && this.formRootRef.current) {
      focusFirstElement(this.formRootRef.current);
    }
  }

  componentDidUpdate(prevProps: FormProps, prevState: State): void {
    if (this.state !== prevState) {
      this.propagateUpdate();
    }
  }

  componentWillUnmount(): void {
    const parentContext = this.context as FormContextState;
    if (parentContext) {
      parentContext.onChildFormChanged(this.props.name, undefined);
    }
  }

  handleFieldMount = (field: Validatable): void => {
    this.setState((prevState): State => {
      if (
        prevState.fields[field.props.name] &&
        prevState.fields[field.props.name].ref !== field
      ) {
        logger.warn(
          "onFieldMount: field already mounted. Duplicate name?",
          field.props.name
        );
      }
      prevState.fields[field.props.name] = {
        name: field.props.name,
        label: field.props.label,
        isValid: field.isValid,
        value: field.props.value,
        validationResults: field.validate(),
        ref: field,
        dirty: false
      };
      return { ...prevState, valid: prevState.valid && field.isValid };
    });
  };

  forceDirty = (): void => {
    this.setState({ dirty: true });
    this.propagateUpdate();
  };

  resetDirty = (dirty: boolean = false): void => {
    this.setState({ dirty, submitted: false });
    Object.keys(this.state.childForms).forEach((k): void => {
      this.state.childForms[k].onResetDirty(dirty);
    });
  };

  handleFieldUnmount = (field: Validatable): void => {
    this.setState((prevState): State => {
      delete prevState.fields[field.props.name];
      return prevState;
    });
  };

  handleSubmit = (e: React.FormEvent): void => {
    logger.debug("form " + this.props.name + " submit");
    e.preventDefault();

    if (this.props.onSubmit) {
      this.props.onSubmit(e, this.state, this.isValid);
    }
    this.setState({
      submitted: true
    });
  };

  handleChildFormChanged = (
    formName: string,
    childState?: FormContextState
  ): void => {
    this.setState((prevState): State => {
      const newState = {
        ...prevState,
        childForms: { ...prevState.childForms }
      };
      if (childState === undefined) {
        delete newState.childForms[formName];
      } else {
        newState.childForms[formName] = childState;
      }
      newState.valid = isValidForm(newState);
      newState.dirty =
        newState.dirty || childState === undefined || childState.dirty;
      return newState;
    });
  };

  // HACK: This should never need to be called from external code. Necessary because validation only happens
  //       immediately on change, before value has been updated by parent component
  // TODO: We needs to automatically revalidate AFTER value update on ValidatedInputs
  revalidate = (cb?: () => void): void => {
    this.setState((prevState): State => {
      const newState = { ...prevState };
      let allValid = true;
      // re-validate every field on this form
      Object.keys(prevState.fields).forEach((k): void => {
        const fieldRef = newState.fields[k].ref;
        if (fieldRef) {
          const validationResults = fieldRef.validate();
          const fieldValid = isValid(validationResults);
          newState.fields[k].isValid = fieldValid;
          newState.fields[k].validationResults = validationResults;
          newState.fields[k].hint = validationResults.find(
            (r): boolean => r !== undefined
          );
          allValid = allValid && fieldValid;
        }
      });
      newState.valid = allValid;
      return newState;
    }, cb);
  };

  get baseClasses(): string[] {
    let result = ["xw-form"];
    if (Array.isArray(this.props.classNames)) {
      result = result.concat(this.props.classNames);
    } else if (
      typeof this.props.classNames === "string" &&
      this.props.classNames !== ""
    ) {
      result.push(this.props.classNames as string);
    }
    if (this.state.submitted) {
      result.push("submitted");
    }

    return result;
  }

  get isDirty(): boolean {
    return this.state.dirty;
  }

  get isValid(): boolean {
    // can not for the life of me figure out why linter thinks I'm modifying state here
    // eslint-disable-next-line react/no-access-state-in-setstate
    return isValidForm(this.state);
  }

  handleFieldValidate = (
    fieldName: string,
    value: unknown,
    results: ValidationResult[]
  ): void => {
    // logger.debug("Validate form field " + fieldName, value, results);
    this.setState((prevState): State => {
      const newState = { ...prevState };

      // re-validate every field on this form
      Object.keys(prevState.fields).forEach((k): void => {
        // current changed field is stale, re-validate later
        if (k === fieldName) {
          newState.fields[k].dirty = true;
          return;
        }
        const fieldRef = newState.fields[k].ref;
        if (fieldRef) {
          const validationResults = fieldRef.validate();
          newState.fields[k].isValid = isValid(validationResults);
          newState.fields[k].validationResults = validationResults;
          newState.fields[k].hint = validationResults.find(
            (r): boolean => r !== undefined
          );
        }
      });

      newState.fields[fieldName] = {
        ...newState.fields[fieldName],
        value,
        isValid: results.every((r): boolean => r === undefined),
        validationResults: results,
        hint: results.find((r): boolean => r !== undefined)
      };
      newState.dirty = true;
      newState.valid = isValidForm(newState);
      return newState;
    });
  };

  propagateUpdate(): void {
    const parentContext = this.context as FormContextState;
    if (parentContext) {
      parentContext.onChildFormChanged(this.props.name, this.state);
    }
    if (this.props.onValidated) {
      this.props.onValidated(this.isValid, this.state);
    }
  }

  render(): JSX.Element {
    const { children, name, ariaLabel } = this.props;
    const hasParent = this.context && this.context !== nullFormContext;
    return (
      <FormContext.Provider value={this.state}>
        {hasParent ? (
          <fieldset
            name={name}
            data-qa={name}
            className={this.baseClasses.join(" ")}
          >
            <div className="field-set-children">{children}</div>
          </fieldset>
        ) : (
          <form
            ref={this.formRootRef}
            name={name}
            data-qa={name}
            onSubmit={this.handleSubmit}
            className={this.baseClasses.join(" ")}
            aria-label={ariaLabel || name}
          >
            {children}
          </form>
        )}
      </FormContext.Provider>
    );
  }
}

import { HashRouter, Route } from "react-router-dom";
import PreSignup from "./preSignup";
import React from "react";
import { Landing } from "./landing";

export default class SignupRoutes extends React.Component {
  render(): React.ReactNode {
    return (
      <HashRouter>
        <Route>
          <Landing />
        </Route>
        <Route exact={true} path={["/trial"]}>
          <PreSignup route="trial" />
        </Route>
        <Route exact={true} path={["/signup"]}>
          <PreSignup route="free" />
        </Route>
      </HashRouter>
    );
  }
}

import { getTimeZoneFromTimeSetting } from "./date";
import { Constants } from "../core/constants";
import { scaleTime, scaleUtc, ScaleTime } from "d3-scale";

/**
 * The complete D3 library is fairly large and has a lot of stuff we won't be using.
 * So only add sub-modules to the package.json (ex: d3-axis) and export stuff you need here.
 *
 * NOTE: @d3/types doesn't allow re-exporting of most of its interfaces, so if you need to ref
 *       an interface, you may need to import directly from your component
 * Ex: import { CurveFactory } from "d3-shape";
 *
 */
export { extent } from "d3-array";

export {
  arc,
  area,
  line,
  pie,
  curveLinear,
  curveNatural,
  curveStepAfter,
  curveStepBefore,
  curveStep
} from "d3-shape";
export { scaleLinear, scaleQuantile, scaleBand } from "d3-scale";
export {
  timeMinute,
  timeHour,
  timeDay,
  timeWeek,
  timeMonth,
  timeYear,
  utcMinute,
  utcHour,
  utcDay,
  utcWeek,
  utcMonth
} from "d3-time";
export {
  sankey,
  sankeyLinkHorizontal,
  sankeyJustify,
  sankeyLeft,
  sankeyCenter,
  sankeyRight
} from "d3-sankey";

export function scaleLocaleTime<TX = number, TY = number>(): ScaleTime<TX, TY> {
  const isUtc = getTimeZoneFromTimeSetting() === Constants.UTC;
  return isUtc ? scaleUtc<TX, TY>() : scaleTime<TX, TY>();
}

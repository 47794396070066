import { booleanValue } from "./string";
import { Constants } from "../core/constants";
import {
  SubscriptionFeature,
  SubscriptionFeatureMap
} from "generated-proto-files/ccsubs_gen_pb";
import { label, logger } from "../core/global";
import { Icon } from "../components/icon/icon";
import * as React from "react";

export const getEnvName = (): string => {
  if (process.env.NODE_ENV === "development") {
    return "dev watch";
  }

  switch (window.location.host) {
    case "localhost:7000":
      return "dev distro";
    case "staging.crosswork.cisco.com":
      return "staging";
    case "canary.crosswork.cisco.com":
      return "canary";
    case "hbird.crosswork.cisco.com":
      return "hbird";
    default:
      if (
        /cw-lab-[0-9]+\.cisco\.com.*/.test(window.location.host) ||
        /.*-cw-dev.*/.test(window.location.host)
      ) {
        return "cw-labs";
      }
      return "production";
  }
};

/* eslint-disable camelcase */
export interface Environment {
  forgot_password_url: string;
  enroll_url: string;
  trust_alarms_enabled: string;
  maintenance: string;
  trial_token: string;
  analytics_url: string;
  analytics_site_id: string;
  analytics_enable: string;
  free_token: string;
  topology_ui_enable: string;
  cdg_auto_enrollment: string;
  prefix_history_lookback_start_date: string;
  rov_lookback_start_date: string;
  alarm_version: string;
}

export const getEnvironment = async (): Promise<Environment> => {
  const response = await fetch("ui_props.json");
  //log ui props to see if the value of alarm version changes
  response
    ?.clone()
    ?.json()
    ?.then((data) => console.log("ui props", data));
  return response && response.json();
};

const getConfig = async (): Promise<Response> => {
  const response = await fetch("ui_props.json");
  return response;
};

export const fetchMaintenanceSetting = (
  callbackFn: (maintenance: boolean) => void
): void => {
  getConfig().then((response) => {
    const headerFlag = response.headers.get("xw-maintenance");
    if (headerFlag && booleanValue(headerFlag)) {
      callbackFn(true);
    } else {
      response.json().then((resp: Environment) => {
        callbackFn(resp.maintenance === "true");
      });
    }
  });
};

export const isAws = (): boolean => {
  return (
    window.location.href.includes(Constants.AWS_REG_INPROGRESS_URL) ||
    window.location.href.includes(Constants.AWS_LANDING_URL) ||
    window.location.href.includes(Constants.AWS_REGISTRATION_URL) ||
    window.location.href.includes(Constants.AWS_ERROR_URL)
  );
};

export const isTrial = (): boolean => {
  return window.location.href.includes(Constants.TRIAL_URL);
};

export const isFree = (): boolean => {
  return window.location.href.includes(Constants.FREE_URL);
};

export const isPreFree = (): boolean => {
  return window.location.href.includes("#/signup");
};

export const isPreTrial = (): boolean => {
  return window.location.href.includes("#/trial");
};

export const isWelcome = (): boolean => {
  return window.location.hash.includes("/welcome");
};

export function isProduction(): boolean {
  return getEnvName() === "production";
}
// @ts-ignore
export const isFirefox = typeof InstallTrigger !== "undefined";
// Disabling chrome webstore and runtime check since Chromium check stopped working from Chrome Build 106
export const isChromium =
  // @ts-ignore
  !!window.chrome; // && (!!window.chrome.webstore || !!window.chrome.runtime);
export const isSupportedBrowser = isChromium || isFirefox;

// add any other application related config here
export interface FeatureConfig {
  rootUri: string;
  label: string;
  icon: React.ReactNode;
  type: SubscriptionFeatureMap[keyof SubscriptionFeatureMap];
}

export const featureConfig = (
  featureType: SubscriptionFeatureMap[keyof SubscriptionFeatureMap],
  featureConfigs?: Array<FeatureConfig>
): FeatureConfig | undefined => {
  if (featureConfigs) {
    const _featureConfig = featureConfigs.find((value) => {
      return value.type === featureType;
    });
    if (_featureConfig) {
      return _featureConfig;
    }
  }
  switch (featureType) {
    case SubscriptionFeature.FEATURE_CCNI:
      return {
        rootUri: "/extRoute",
        label: label.networkInsights,
        icon: <Icon name={"menu-external-routing"} />,
        type: SubscriptionFeature.FEATURE_CCNI
      };
    case SubscriptionFeature.FEATURE_CCTI:
      return {
        rootUri: "/trust",
        label: label.trustInsights,
        icon: <Icon name={"menu-trust-insights"} />,
        type: SubscriptionFeature.FEATURE_CCTI
      };
    case SubscriptionFeature.FEATURE_CCTA:
      return {
        rootUri: "/traffic",
        label: label.trafficAnalysis,
        icon: <Icon name={"menu-traffic-analysis"} />,
        type: SubscriptionFeature.FEATURE_CCTA
      };
    default:
      logger.warn("invalid feature config defined ");
  }
  return undefined;
};

export type StringTransform<T = string> = (input: T) => string;

export interface ToStringable {
  toString(): string;
}
export const initials = (input: string): string => {
  if (input === "") {
    return "";
  }

  const parts = input.split(/\s+/);
  if (parts.length < 2) {
    return input[0].toUpperCase();
  }
  return parts[0][0].toUpperCase() + parts[parts.length - 1][0].toUpperCase();
};

export function formatPhoneNumber(
  input: string,
  countryCode: string = "US"
): string {
  const numbers = input.replace(/\D/g, "");

  switch (countryCode) {
    case "US":
      if (numbers.length === 10) {
        return `1-${numbers.slice(0, 3)}-${numbers.slice(3, 6)}-${numbers.slice(
          6
        )}`;
      } else {
        return "1-" + numbers;
      }
    default:
      return input;
  }
}

export function stringAccessor<T = string>(
  stringOrTransform: string | StringTransform<T> | undefined,
  param: T
): string | undefined {
  return typeof stringOrTransform === "function"
    ? stringOrTransform(param)
    : stringOrTransform;
}

export function wordCount(input: string): number {
  const trimmed = input.trim();
  if (trimmed.length <= 0) {
    return 0;
  }
  return trimmed.split(/\s+/).length;
}

export function booleanValue(value: string): boolean {
  return value.toLowerCase() === "true";
}

export function passwordify(value: string): string {
  return value.replace(/./g, "*");
}

export function titleCase(val: string): string {
  const str = val.toLowerCase().split(" ");
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

export function hexEncode(val: string): string {
  let hex;
  let result = "";
  for (let i = 0; i < val.length; i++) {
    hex = val.charCodeAt(i).toString(16);
    result += ("000" + hex).slice(-4);
  }

  return result;
}

export function hexDecode(val: string): string {
  let str = "";
  for (let i = 0; i < val.length && val.substr(i, 2) !== "00"; i += 2)
    str += String.fromCharCode(parseInt(val.substr(i, 2), 16));
  return str;
}

export const stringToArray = (
  value: string | undefined,
  separator: string = ","
): string[] => {
  if (value) {
    if (value.includes(separator)) {
      return value.split(separator);
    }
    return [value];
  }
  return [];
};

import { EventEmitter } from "eventemitter3";
import { Constants } from "../constants";

export const events = new EventEmitter();

export default class PendingRequests {
  private activeRequest: Map<string, boolean>;
  private count: number;

  constructor() {
    this.activeRequest = new Map();
    this.count = 0;
  }

  isLoading(): boolean {
    return !!this.count;
  }

  has(dataKey: string): boolean {
    // nulling out deleted values should be more performant, so need to check value instead of has
    return !!this.activeRequest.get(dataKey);
  }

  // TODO: this could double count statuses that already exist...
  setPending(dataKey: string): void {
    this.activeRequest.set(dataKey, true);
    // enables sidebar
    this.count++;
    events.emit(Constants.EVENT_REQUEST_START, this.count);
  }

  setDone(dataKey: string): void {
    this.activeRequest.set(dataKey, false);
    this.count--;
    events.emit(Constants.EVENT_REQUEST_END, this.count);
  }
}

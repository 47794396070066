import { label, labelParam } from "../../core/global";
import moment from "moment-timezone";
import { Constants } from "../../core/constants";
import * as ipaddr from "ip6addr";
import { isProduction } from "../../utils/env";

export type ValidationResult = string | undefined;
export type ValidatorFn<T = string> = (input: T) => ValidationResult;

export function isValid(results: ValidationResult[]): boolean {
  return results.length <= 0 || results.every((r): boolean => r === undefined);
}

// Bare minimum input component properties required for validation to work
export interface ValidationProps<T = string> {
  name: string;
  label?: string;
  value: T;
  onChange?: (newVal: T, isValid: boolean) => void;
  validators: ValidatorFn<T>[];
}

// the minimum set of things that a form needs to treat a child component as a validated field
export interface Validatable {
  props: { name: string; value: unknown; label?: string };
  isValid: boolean;
  validate(): ValidationResult[];
}

// Regexes used to help validators.
export const splitRegex: RegExp = /[ \t\r\n;,]+/;
const allowedCSVFileExtensionRegex = /(csv)$/i;
const asnRegex: RegExp =
  /^([1-5]\d{4}|[1-9]\d{0,3}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])(\.([1-5]\d{4}|[1-9]\d{0,3}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5]|0))?$/;
const ipv4Regex: RegExp =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const fqdnRegex =
  /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/;
// Daniel's JSFiddle : http://jsfiddle.net/DanielD/8S4nq/
const ipV46Regex: RegExp =
  /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))(\/([0-9]|[1-2][0-9]|3[0-2]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?(\/([0-9]|[1-9][0-9]|[1][0-2][0-8]|119|109))\s*$))/;
// Same Reg as above without subnet check
const ipRegex: RegExp =
  /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/;
const ipV46MoreSpecificRegex: RegExp =
  /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))(\/([2][3-9]|3[0-2]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?(\/([2][3-9]|[3-9][0-9]|[1][0-2][0-8]))\s*$))/;
const validPhoneNumberRegex = /^[^{}~`!@#$%^&*":';?\n]{8,20}$/;
const slackWebhookRegex = /^https:\/\/hooks.slack.com\/services\/.+\/.+\/.+$/;
const webexWebhookRegex =
  /^https:\/\/(api.ciscospark.com|webexapis.com)\/v1\/webhooks\/incoming\/.+$/;
const microsoftTeamsWebhookRegex = /^https:\/\/.+$/;
const base64Regex = /^[A-Za-z0-9+/=]+$/;
const numericRegex = /^[0-9]*$/;
const nameAsPerBERegex = /^[^{}~`!#$%^*":';?[\]()<>,\\|=\t]{1,120}$/; // special characters not allowed in BE: "{}~`!#$%^*\":';?[]()<>,\\|=\t" and max length = 120
const nonAllowedCharsAtStartOrEndRegex = /^[^-_+/@.&](.*[^-_+/@.&])?$/; // special characters not allowed in BE at the start or end of string: -_+/@.&
const hostnameIpRegex =
  /(^\s*((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))\s*$)|(^\s*((?=.{1,255}$)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*\.?)\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$)/;
// Special case (subnet) must be >= 1 && <= 31
const ipv4WithSubnetForStaticRouteIp =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])((\/([1-9]|[1-2][0-9]|3[0-1])))$/;
export const asNameRegex = /^[0-9a-zA-Z ]{1,120}$/;

/*
 * Class consisting of validation methods used across various input components.
 * */

export class Validators {
  static asnRegex = asnRegex;

  static validateTags = (tags?: string[]): ValidationResult => {
    if (!tags) {
      return undefined;
    }

    const validateArrayLength = Validators.maxArrayLength(
      Constants.MAX_TAGS_PER_ITEM,
      label.tag,
      label.tags
    )(tags);

    const validateItemLength = Validators.maxItemLength(
      Constants.MAX_TAG_LENGTH
    )(tags);

    if (validateArrayLength) {
      return validateArrayLength;
    }
    if (validateItemLength) {
      return validateItemLength;
    }

    return undefined;
  };

  static portNumber = (
    value: number | string | undefined
  ): string | undefined => {
    if (!value) {
      return undefined;
    }
    return value >= 1 && value <= 65535 ? undefined : label.invalidPortNumber;
  };
  static nonZeroInteger = (value: number | undefined): ValidationResult => {
    return value === undefined || /^([1-9]\d*)$/.test(value.toString())
      ? undefined
      : label.nonZeroIntegerUptoTenChars;
  };

  static maxItemLength =
    (
      max: number
    ): ((value: string[] | string | undefined) => ValidationResult) =>
    (value: string[] | string | undefined): ValidationResult => {
      const lengthValidation = (
        strValue: string | undefined
      ): ValidationResult =>
        strValue && strValue.length > max
          ? labelParam(label.maxLength, { max })
          : undefined;
      if (Array.isArray(value)) {
        for (const v of value) {
          const result = lengthValidation(v);
          if (result) {
            return result;
          }
        }
      } else {
        return lengthValidation(value);
      }
      return undefined;
    };

  static minItemLength =
    (min: number): ((value: string) => string | undefined) =>
    (value: string): string | undefined => {
      return value && value !== "" && value.length < min
        ? labelParam(label.minLength, { min })
        : undefined;
    };

  static minValue =
    (
      min: number,
      hint?: string
    ): ((value: number | undefined) => string | undefined) =>
    (value: number | undefined): string | undefined => {
      return value === undefined || value >= min
        ? undefined
        : hint || labelParam(label.minValueHint, { min });
    };

  static maxValue =
    (
      max: number,
      hint?: string
    ): ((value: number | undefined) => string | undefined) =>
    (value: number | undefined): string | undefined => {
      return value === undefined || value <= max
        ? undefined
        : hint || labelParam(label.maxValueHint, { max });
    };

  static validRange =
    (
      min: number,
      max: number,
      message?: string
    ): ((value: number) => string | undefined) =>
    (value: number): string | undefined => {
      if (value && value >= min && value <= max) {
        return undefined;
      } else {
        return (
          message ||
          labelParam(label.invalidRange, {
            min: min.toLocaleString(),
            max: max.toLocaleString()
          })
        );
      }
    };

  static validRangeList = (
    min: number,
    max: number,
    hint?: string
  ): ((value: number[]) => ValidationResult) => {
    return (value): ValidationResult => {
      if (value === undefined || value.length <= 0) {
        return undefined;
      }
      const rangeValidator = Validators.validRange(min, max, hint);
      return value
        .map(rangeValidator)
        .find((result): boolean => result !== undefined);
    };
  };

  static required<T = string>(value: T): ValidationResult {
    if (typeof value === "string") {
      return value && value.trim() !== "" && value !== "NaN"
        ? undefined
        : label.required;
    }
    if (typeof value === "number" && isNaN(value as number)) {
      return label.required;
    }
    if (Array.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (value as any[]).length > 0 ? undefined : label.required;
    }
    return value !== undefined ? undefined : label.required;
  }

  static number = (value: string): string | undefined => {
    return value && isNaN(Number(value)) ? label.mustBeNumber : undefined;
  };

  static numberRange = (
    min?: number,
    max?: number
  ): ((value: number | undefined) => boolean) => {
    return (value: number | undefined): boolean => {
      return (
        value === undefined || ((!min || value > min) && (!max || value < max))
      );
    };
  };

  static isWholeNumber = (value: number | string): string | undefined => {
    return value && (isNaN(Number(value)) || Number(value) % 1 !== 0)
      ? label.mustBeNumber
      : undefined;
  };

  static email = (value: string): ValidationResult => {
    return value &&
      !/^[A-Z0-9._%+-]{1,128}@([A-Z0-9-]{1,63})(\.[A-Z0-9-]{1,63})+$/i.test(
        value
      )
      ? label.invalidEmail
      : undefined;
  };

  static alphaNumeric = (value: string): string | undefined => {
    return value && /[^a-zA-Z0-9 ]/i.test(value)
      ? undefined
      : label.onlyAlphaNumeric;
  };

  static ipv4Length = (value: string): string | undefined => {
    return value &&
      /^(([0-9]{1}|1[0-9]|2[0-9]|3[0-2])(,([0-9]{1}|1[0-9]|2[0-9]|3[0-2]))*)?$/i.test(
        value
      )
      ? undefined
      : label.invalidIPv4Length;
  };

  static ipv6Length = (value: string): string | undefined => {
    return value &&
      /^(([0-9]{1,2}|1[0-1][0-9]|12[0-8])(,([0-9]{1,2}|1[0-1][0-9]|12[0-8]))*)?$/i.test(
        value
      )
      ? undefined
      : label.invalidIPv6Length;
  };

  static integer(value: number | undefined): ValidationResult {
    return value !== undefined && Number.isInteger(value)
      ? undefined
      : label.invalidInteger;
  }

  static integerRange(
    min: number,
    max: number
  ): (value: number | undefined) => ValidationResult {
    return (value: number | undefined): ValidationResult => {
      return value === undefined ||
        (Number.isInteger(value) && value >= min && value <= max)
        ? undefined
        : labelParam(label.invalidRange, { min, max });
    };
  }

  static integerMaxDigits10 = (value: string): string | undefined => {
    const intRx: RegExp = /^\d{1,10}$/;
    if (!intRx.test(value)) {
      return label.integerUptoTenChars;
    }
    return undefined;
  };

  static integerNonZeroMaxDigits10 = (value: string): string | undefined => {
    const intRx: RegExp = /^[0-9]*[1-9][0-9]*$/;
    if (!intRx.test(value)) {
      return label.nonZeroIntegerUptoTenChars;
    }
    return undefined;
  };

  static isValidRegex = (value: string): string | undefined => {
    try {
      new RegExp(value);
    } catch (e) {
      return label.invalidRegex;
    }
    return undefined;
  };

  static requiredFile = (value: FileList): string | undefined => {
    if (value && value.length > 0) {
      return undefined;
    } else {
      return label.required;
    }
  };

  static validateFileType = (
    value: FileList,
    allowedFilesRegex = allowedCSVFileExtensionRegex
  ): RegExpExecArray | null | undefined => {
    let result;
    if (value && value.length > 0 && value[0].name !== "") {
      const splittedValue = value[0].name.split(".").pop();
      result = allowedFilesRegex.exec(splittedValue || "");
    }
    return result;
  };

  static validateFileSize = (value: FileList): string | undefined => {
    if (value && value.length > 0 && value[0].size > 0) {
      const filesize = value[0].size / 1024 / 1024; // in MB
      if (filesize > 10) {
        return label.fileSizeExceeds10MB;
      }
    }
    return undefined;
  };

  static ipv46Address = (value: string): string | undefined => {
    if (!ipV46Regex.test(value)) {
      return label.invalidIPv4IPv6;
    }
    return undefined;
  };

  static acceptAgreement = (value: boolean): string | undefined => {
    return value ? undefined : label.acceptAgreement;
  };

  static ipV46MoreSpecific = (value: string): string | undefined => {
    if (!ipV46MoreSpecificRegex.test(value)) {
      return label.invalidIPv4IPv6;
    }
    return undefined;
  };

  static validateAsnOrPrefix = (value: string): string | undefined => {
    if (
      value.length > 0 &&
      !ipV46Regex.test(value) &&
      Validators.validateAsNumber(value) !== undefined
    ) {
      return label.invalidPrefixOrAsn;
    }
    return undefined;
  };

  static validateAsnOrPrefixOrIp = (value: string): string | undefined => {
    // is asn?
    if (Validators.validateAsNumber(value) === undefined) {
      return undefined;
    }
    // is prefix?
    if (ipV46Regex.test(value)) {
      return undefined;
    }
    // is ip?
    try {
      ipaddr.parse(value);
    } catch {
      return label.invalidAsnOrPrefixOrIP;
    }
    return undefined;
  };

  static validateAsnOrAsNameOrPrefixOrIp = (
    value: string
  ): string | undefined => {
    // check if value has special characters
    if (!/^[0-9a-zA-Z:./]*$/.test(value as string)) {
      return label.invalidCharacters;
    }
    // check if asn
    if (Validators.validateNumeric(value) === undefined) {
      if (Validators.validateAsNumber(value) === undefined) {
        return undefined;
      } else {
        return label.invalidASN;
      }
    } else if (asNameRegex.test(value)) {
      return undefined;
    } else {
      // check if ip
      if (Validators.ipv46Address(value) === undefined) {
        return undefined;
      } else {
        try {
          ipaddr.parse(value);
        } catch {
          return label.invalidIp;
        }
        return undefined;
      }
    }
  };

  static validateListItems = (
    value: string,
    validator: (row: string) => string | undefined
  ): string | undefined => {
    let errorValues: string;
    const errors: string[] = [];
    let result: string | undefined;

    const rows = value.split(splitRegex); // lets first split the values

    // validate individual values
    rows.forEach((row: string): void => {
      result = validator(row);
      if (result) {
        errors.push(row);
      }
    });

    if (errors.length > 0) {
      errorValues = errors.join(", ");
      return errorValues;
    }
    return undefined;
  };

  static validateIPV46AddressList = (value: string): string | undefined => {
    if (!value) {
      return undefined;
    }

    const errorListValues: string | undefined = Validators.validateListItems(
      value,
      Validators.ipv46Address
    );
    let result = undefined;
    if (errorListValues) {
      result = labelParam(label.invalidIPaddresses, {
        errorListValues: errorListValues
      });
    }
    return result;
  };

  static validateEmailAddressList = (value: string): string | undefined => {
    if (!value) {
      return undefined;
    }

    const errorListValues: string | undefined = Validators.validateListItems(
      value,
      Validators.email
    );
    let result = undefined;
    if (errorListValues) {
      result = labelParam(label.invalidEmail, {
        errorListValues: errorListValues
      });
    }
    return result;
  };

  static validateIPV4List = (value: string): string | undefined => {
    if (!value) {
      return undefined;
    }

    const errorListValues: string | undefined = Validators.validateListItems(
      value,
      Validators.validIpv4
    );
    let result = undefined;
    if (errorListValues) {
      result = labelParam(label.invalidIPaddresses, {
        errorListValues: errorListValues
      });
    }
    return result;
  };

  static validateIpv4StaticRouteList = (value: string): string | undefined => {
    if (!value) {
      return undefined;
    }

    const errorListValues: string | undefined = Validators.validateListItems(
      value,
      Validators.validIpv4ForStaticRoute
    );
    let result = undefined;
    if (errorListValues) {
      result = labelParam(label.invalidIPaddresses, {
        errorListValues: errorListValues
      });
    }
    return result;
  };

  static validateASNs = (value: string | undefined): string | undefined => {
    if (!value) {
      return undefined;
    }

    const errorListValues: string | undefined = Validators.validateListItems(
      value,
      Validators.validateAsNumber
    );
    let result = undefined;
    if (errorListValues) {
      result = labelParam(label.invalidAsns, {
        errorListValues: errorListValues
      });
    }
    return result;
  };

  static validateBGPUpdatesASN = (val: string): string | undefined => {
    if (val === "") return undefined;
    if (
      Validators.validateAsNumber(val) !== undefined ||
      Validators.externalASN(val) !== undefined
    ) {
      return label.invalidASN;
    }
    return undefined;
  };

  static validateBGPUpdatesAsnList = (
    value: string | undefined
  ): string | undefined => {
    if (!value) {
      return undefined;
    }

    const errorListValues: string | undefined = Validators.validateListItems(
      value,
      Validators.validateBGPUpdatesASN
    );

    let result = undefined;
    if (errorListValues) {
      result = labelParam(label.invalidAsns, {
        errorListValues: errorListValues
      });
    }
    return result;
  };

  static validatePeerId = (val: string): string | undefined => {
    if (val === "") return undefined;
    const isNumber = numericRegex.test(val);
    const num = parseInt(val);
    return isNumber && num > 0 && num <= Constants.UINT32MAX
      ? undefined
      : label.invalidPeerId;
  };

  static validatePeerIdList = (value: string): string | undefined => {
    if (!value) {
      return undefined;
    }

    const errorListValues: string | undefined = Validators.validateListItems(
      value,
      Validators.validatePeerId
    );
    let result = undefined;
    if (errorListValues) {
      result = labelParam(label.invalidPeerIds, {
        errorListValues: errorListValues
      });
    }
    return result;
  };

  static validateBGPMed = (val: string): string | undefined => {
    if (val === "") return undefined;
    const num = parseInt(val);
    return !isNaN(num) && num >= 0 && num <= Constants.UINT32MAX
      ? undefined
      : label.invalidMed;
  };

  static validateBGPMedList = (value: string): string | undefined => {
    if (!value) {
      return undefined;
    }

    const errorListValues: string | undefined = Validators.validateListItems(
      value,
      Validators.validateBGPMed
    );
    let result = undefined;
    if (errorListValues) {
      result = labelParam(label.invalidMedList, {
        errorListValues: errorListValues
      });
    }
    return result;
  };

  static validateNumeric(value: string): ValidationResult {
    return value === "" || (numericRegex.test(value) && !isNaN(Number(value)))
      ? undefined
      : label.invalidNumber;
  }

  // Used by both asn as Number input or textarea field
  static validateAsNumber = (
    value: number | string | string[] | undefined
  ): ValidationResult => {
    if (!value) {
      return undefined;
    }

    const error = label.invalidASN;
    if (Array.isArray(value)) {
      return value
        .map((v): ValidationResult => Validators.validateAsNumber(v))
        .find((v): boolean => typeof v === "string");
    }
    let newValue = value;
    if (typeof value === "string") {
      const result =
        Validators.number(value) || Validators.validateNumeric(value);
      if (value === "" || !result) {
        newValue = value;
      } else {
        return error;
      }
    }

    if (!!Validators.isWholeNumber(newValue as number)) {
      return error;
    }

    if (newValue <= 0 || newValue >= Constants.ASN_MAX) {
      return error;
    }
    return undefined;
  };

  static validatePartialPrefix = (value: string): string | undefined => {
    if (/[^0-9a-fA-F:./]/.test(value)) {
      return label.invalidPrefix;
    }
    return undefined;
  };

  static externalASN(value: string | number): ValidationResult {
    const valueNumber = typeof value === "string" ? parseInt(value) : value;
    if (valueNumber < 0) {
      return label.invalidPositiveNumber;
    } else if (valueNumber === 0) {
      return label.reservedForPrivateUse;
    } else if (valueNumber === 23456) {
      return label.reservedForASPoolTransition;
    } else if (valueNumber >= 64496 && valueNumber <= 64511) {
      return label.reservedForDocumentation;
    } else if (valueNumber >= 64512 && valueNumber <= 65535) {
      return label.reservedForPrivateUse;
    } else if (valueNumber >= 65536 && valueNumber <= 65551) {
      return label.reservedForDocumentation;
    } else if (valueNumber >= 4200000000 && valueNumber <= 4294967295) {
      return label.reservedForPrivateUse;
    }
    return undefined;
  }

  static dateGreaterThanToday = (
    value: number | undefined
  ): ValidationResult => {
    if (!value) {
      return undefined;
    }
    const tomorrowsDate = +moment().add(1, "day").startOf("day");
    if (value < tomorrowsDate) {
      return label.dateGreaterThanToday;
    }
    return undefined;
  };

  static dateGreaterThanEqualToday = (
    value: number | undefined
  ): ValidationResult => {
    if (!value) {
      return undefined;
    }
    const todaysDate = +moment().startOf("day");
    if (value < todaysDate) {
      return label.dateLessthanTodayWarning;
    }
    return undefined;
  };

  static validateRoaAsnsString = (value: string): string | undefined => {
    if (value === "") return undefined;
    const allowedChars = /^[0-9]+( [0-9]+)*$/;
    if (!allowedChars.test(value)) {
      return label.roaAsnFilterErrorMsg;
    }

    const errorListValues: string | undefined = Validators.validateListItems(
      value,
      Validators.validateAsNumber
    );
    let result = undefined;
    if (errorListValues) {
      result = labelParam(label.invalidAsns, {
        errorListValues: errorListValues
      });
    }
    return result;
  };

  static validateAsPathString = (asPath: string): string | undefined => {
    if (asPath === "") return undefined;
    const allowedChars = /^[0-9]+( [0-9]+)*$/;
    if (!allowedChars.test(asPath)) {
      return label.asPathFilterErrorMsg;
    }

    const errorListValues: string | undefined = Validators.validateListItems(
      asPath,
      Validators.validateAsNumber
    );
    let result = undefined;
    if (errorListValues) {
      result = labelParam(label.invalidAsns, {
        errorListValues: errorListValues
      });
    }
    return result;
  };
  static validateAsPath = (asPathList: string[]): string | undefined => {
    if (!asPathList) {
      return undefined;
    }

    let result: string | undefined;
    let errorValues: string;
    const errors: string[] = [];
    for (const paths of asPathList) {
      const asns: string[] = paths.split(splitRegex);
      // validate individual values
      for (const asn of asns) {
        if (asn) {
          result = Validators.integerMaxDigits10(asn);
          if (result) {
            errors.push(asn);
          }
        }
      }
    }

    if (errors.length > 0) {
      errorValues = errors.join(", ");
      return label.invalidASPaths + errorValues;
    }
    return undefined;
  };

  static positiveOrZeroNumber = (val: number | undefined): ValidationResult => {
    if (val === undefined || val >= 0) {
      return undefined;
    }
    return label.invalidPositiveNumber;
  };

  static numberRangeList = (
    min: number,
    max: number,
    hint: string
  ): ((val: string) => string | undefined) => {
    return (val: string): string | undefined => {
      if (val === "") {
        return undefined;
      }
      try {
        return !val
          .split(",")
          .map((n): number => parseInt(n))
          .some((n): boolean => isNaN(n) || n < min || n > max)
          ? undefined
          : hint;
      } catch {
        return hint;
      }
    };
  };

  static validCountryCode(val: string): ValidationResult {
    if (val === "") return undefined;
    const num = parseInt(val);
    return !isNaN(num) && num >= 1 && num <= 1000
      ? undefined
      : label.invalidCountryCode;
  }

  static validPhoneNumber(val: string): ValidationResult {
    if (val === "") return undefined;
    return validPhoneNumberRegex.test(val) ? undefined : label.invalidPhone;
  }

  static validSlackWebhook(val: string): ValidationResult {
    return val === "" || slackWebhookRegex.test(val)
      ? undefined
      : label.invalidSlackWebhook;
  }

  static validWebexWebhook(val: string): ValidationResult {
    return val === "" || webexWebhookRegex.test(val)
      ? undefined
      : label.invalidWebexWebhook;
  }

  static validMicrosoftTeamsWebhook(val: string): ValidationResult {
    return val === "" || microsoftTeamsWebhookRegex.test(val)
      ? undefined
      : label.invalidMicrosoftTeamsWebhook;
  }

  static validFqdnOrIp(val: string): ValidationResult {
    return val === "" ||
      ipv4Regex.test(val) ||
      fqdnRegex.test(val) ||
      (!isProduction() && val === "localhost")
      ? undefined
      : label.invalidIpOrDomain;
  }

  static validIpv4(val: string): ValidationResult {
    return val === "" || ipv4Regex.test(val) ? undefined : label.invalidIp;
  }

  static validIpv4ForStaticRoute(val: string): ValidationResult {
    return val === "" || ipv4WithSubnetForStaticRouteIp.test(val)
      ? undefined
      : label.invalidIp;
  }

  static validIp(val: string): ValidationResult {
    return val === "" || ipRegex.test(val) ? undefined : label.invalidIp;
  }

  static validS3Id(val: string): ValidationResult {
    return val === "" ||
      val === "REMOVED_AccessKeyId" ||
      (val.length >= 16 && val.length <= 128)
      ? undefined
      : label.invalidS3Id;
  }

  // A basic check that only valid base64 characters exist on the string.
  // Doesn't garuantee that it decodes to anything meaningful!
  static validS3Secret(val: string): ValidationResult {
    return val === "" ||
      val === "REMOVED_SecretAccessKey" ||
      base64Regex.test(val)
      ? undefined
      : label.invalidS3Secret;
  }

  static validNotesLength(value: string): string | undefined {
    return value && value.length > 2000
      ? labelParam(label.notesMaxLength, { max: 2000 })
      : undefined;
  }

  static validRegex(
    tester: RegExp,
    hint?: string
  ): (value: string) => ValidationResult {
    return (value): ValidationResult => {
      return !value || value === "" || tester.test(value)
        ? undefined
        : hint || label.invalidCharacters;
    };
  }

  static validNameAsPerBE(val: string): ValidationResult {
    return val === "" || nameAsPerBERegex.test(val)
      ? undefined
      : label.invalidName;
  }

  static validateName(value: string): ValidationResult {
    const errors: string[] = [];
    if (value === "") {
      return undefined;
    }
    if (value.length > 120 || value.length < 3) {
      return label.invalidNameLength;
    }
    if (!nonAllowedCharsAtStartOrEndRegex.test(value)) {
      return label.invalidCharsAtStartOrEnd;
    }
    const characters = value.split("");
    // validate each character
    characters.forEach((char: string): void => {
      if (Validators.validNameAsPerBE(char)) {
        errors.push(char);
      }
    });

    if (errors.length) {
      return labelParam(label.invalidName, {
        errorListValues: errors.join(" ")
      });
    }
    return undefined;
  }

  static validHostnameIp(value: string): ValidationResult {
    return value === "" || hostnameIpRegex.test(value)
      ? undefined
      : label.invalidHostname;
  }

  static maxArrayLength(
    maxLen: number,
    itemLabel: string = label.item,
    itemsLabel: string = label.items
  ): (value: unknown[] | unknown | undefined) => ValidationResult {
    return (value: unknown[] | unknown | undefined): ValidationResult => {
      // accepting a non-array type is just for compatability with select input
      // passing a non-array here is considered a validation error (and shouldn't happen in live code)
      if (value && !Array.isArray(value)) {
        return label.validArray;
      }
      return Array.isArray(value) && value.length > maxLen
        ? labelParam(label.validMaxArrayLength, {
            maxLen,
            items: value.length > 1 ? itemsLabel : itemLabel
          })
        : undefined;
    };
  }

  static notString(cantBe: string): (value: string) => ValidationResult {
    return (value: string): ValidationResult => {
      if (cantBe === value) {
        return labelParam(label.cantBeFmt, { value });
      }
      return undefined;
    };
  }

  static validateTelemetryPort(value: number | undefined): ValidationResult {
    if (value === undefined) {
      return undefined;
    }
    return value >= 1024 && value <= 65535
      ? undefined
      : label.invalidTelemetryPort;
  }

  static prefix(value: string | undefined): ValidationResult {
    if (!value || value.length === 0) {
      return undefined;
    }
    return ipV46Regex.test(value) ? undefined : label.invalidPrefix;
  }

  static validateROAMaxLength(value: string): string | undefined {
    if (value === undefined) {
      return undefined;
    }
    if (value && isNaN(Number(value))) {
      return label.mustBeNumber;
    }
    return !isNaN(Number(value)) && Number(value) >= 1 && Number(value) <= 128
      ? undefined
      : label.invalidROAMaxLength;
  }
}

import React from "react";
import "./expander.scss";
import { Icon } from "../icon/icon";
import { Button } from "../form/button/button";
import { CSSTransition } from "react-transition-group";

interface Props {
  name: string;
  header: React.ReactNode;
  subHeader?: React.ReactNode;
  children: React.ReactNode;
  headerCls?: string;
  onClick?: (name: string, expanded: boolean) => void;
}

interface State {
  expanded: boolean;
}
export class Expander extends React.Component<Props, State> {
  static defaultProps = {
    headerCls: "header"
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: false
    };
  }
  handleExpanderClick = (): void => {
    const { onClick, name } = this.props;
    const { expanded } = this.state;
    this.setState({ expanded: !expanded }, () => {
      if (onClick) {
        onClick(name, expanded);
      }
    });
  };

  render(): React.ReactNode {
    const { header, children, headerCls, name, subHeader } = this.props;
    const { expanded } = this.state;
    const expandedCls = expanded ? "rotate-270" : "rotate-45";
    return (
      <div className="xw-section-expander" data-qa={name}>
        <div className="section-header">
          <Button
            name="expand"
            classNames="expander"
            onClick={this.handleExpanderClick}
          >
            <Icon name="circle-close" classNames={expandedCls} />
          </Button>

          <div className={headerCls}>{header}</div>
          {subHeader && !expanded && (
            <div className="sub-header">{subHeader}</div>
          )}
        </div>

        <CSSTransition
          timeout={200}
          in={expanded}
          mountOnEnter={true}
          unmountOnExit={true}
          classNames="node"
        >
          <div className="content">{children}</div>
        </CSSTransition>
      </div>
    );
  }
}

import {
  OrgGroup,
  OrgGroupMap,
  UiTheme,
  UiThemeMap
} from "generated-proto-files/auth_user_pb";
import { put } from "../core/service";
import moment from "moment-timezone";
import { label, logger } from "../core/global";
import {
  SubscriptionFeature,
  SubscriptionFeatureMap,
  SubscriptionProductId,
  SubscriptionProductIdMap
} from "generated-proto-files/ccsubs_gen_pb";
import { unreachable } from "../utils/switch";
import {
  SubscriptionType,
  SubscriptionTypeMap
} from "generated-proto-files/subscriptions_pb";
import {
  CdgContainerType,
  CdgContainerTypeMap
} from "generated-proto-files/ccios_gen_pb";
import { reverseMapping } from "../utils/enum";
import { Constants } from "../core/constants";
import { NIL as NIL_UUID } from "uuid";

type OrgGroup = OrgGroupMap[keyof OrgGroupMap];
type UiTheme = UiThemeMap[keyof UiThemeMap];

export interface UserClaims {
  email: string;
  groups?: OrgGroup[];
  name: string;
  full_name?: string;
  prov: string;
  sub: string;
  org: string;
  iss: string;
  iat: number;
  exp: number;
  aud: string;
  userId: string;
}

export interface Organization {
  orguuid: string;
  orgname: string;
  role: OrgGroup;
}

export interface AccessToken {
  claims: UserClaims;
  expired: boolean;
}

export interface RefreshToken {
  expired: boolean;
  claims: {
    exp: number;
    iat: number;
    sub: string;
  };
}

export interface UserState {
  AccessToken: AccessToken;
  RefreshToken: RefreshToken;
}

export interface UserPrefs {
  theme: UiTheme;
  timezone: string;
}

let _currentUser: UserState;

export const checkValidSession = async (): Promise<UserState> => {
  const resp = await fetch("/session/tokens/introspect", {
    method: "GET"
  });
  const user = (await resp.json()) as UserState;
  _currentUser = user;
  return user;
};

export function getCurrentUser(): UserState | undefined {
  return _currentUser;
}

export function getCurrentUserClaims(): UserClaims | undefined {
  return _currentUser ? _currentUser.AccessToken.claims : undefined;
}

export function isAdmin(): boolean {
  return _currentUser &&
    _currentUser.AccessToken.claims.groups &&
    _currentUser.AccessToken.claims.groups.length > 0
    ? _currentUser.AccessToken.claims.groups[0].toString() ===
        OrgGroup.ADMIN.toString()
    : false;
}

export function isReadOnly(): boolean {
  return _currentUser &&
    _currentUser.AccessToken.claims.groups &&
    _currentUser.AccessToken.claims.groups.length > 0
    ? _currentUser.AccessToken.claims.groups[0].toString() ===
        OrgGroup.READ_ONLY.toString()
    : false;
}

const DEFAULT_USER_PREFS = {
  theme: UiTheme.DARK,
  timezone: moment.tz.guess()
};

const _currentUserPreferences: UserPrefs = {
  ...DEFAULT_USER_PREFS
};

export function getCurrentUserPreferences(): UserPrefs {
  return _currentUserPreferences;
}

export const getThemeCls = (theme: UiTheme): string => {
  switch (theme) {
    case UiTheme.DARK:
      return "theme-dark";
    case UiTheme.LIGHT:
      return "theme-light";
    case UiTheme.HIGH_CONTRAST:
      return "theme-high-Contrast";
    default:
      return "theme-dark";
  }
};

export function isCiscoUser(): boolean {
  return (
    !!getCurrentUserClaims()?.name?.endsWith("@cisco.com") ||
    !!getCurrentUserClaims()?.sub?.endsWith("@cisco.com")
  );
}

export function updateTheme(): void {
  if (document.body) {
    document.body.className = getThemeCls(_currentUserPreferences.theme);
  }
}

function setMomentTimezone(tz: string): void {
  moment.tz.setDefault(tz);
  logger.debug(`Current Timezone: ${tz}`);
  logger.debug(`Current Time: ${moment().toString()}`);
}

export function setCurrentUserPreferences(userPreferences: UserPrefs): void {
  _currentUserPreferences.theme = userPreferences.theme;
  _currentUserPreferences.timezone = userPreferences.timezone;
  // moment zone can either be UTC or local timezone. These are the only 2 options available to user atm.
  const mTz =
    userPreferences.timezone === Constants.UTC
      ? userPreferences.timezone
      : moment.tz.guess();
  setMomentTimezone(mTz);
  updateTheme();
}

// Convert to gRPC call after OCT-1938 is fixed
export const setUserOrg = async (Org: string): Promise<void> => {
  // @ts-ignore
  return put("/session/tokens/update", { Org }, false);
};

export function getCurrentUserRole(): OrgGroup {
  const currentUserClaims = getCurrentUserClaims();
  return currentUserClaims && currentUserClaims.groups
    ? currentUserClaims.groups[0] || OrgGroup.INVALID_ORG_GROUP
    : OrgGroup.INVALID_ORG_GROUP;
}

export const getSubscriptionLabel = (
  type: SubscriptionFeatureMap[keyof SubscriptionFeatureMap]
): string => {
  switch (type) {
    case SubscriptionFeature.FEATURE_CCTA:
      return label.trafficAnalysis;
    case SubscriptionFeature.FEATURE_CCTI:
      return label.trustInsights;
    case SubscriptionFeature.FEATURE_CCNI:
      return label.externalRouteAnalysis;
    case SubscriptionFeature.INVALID_FEATURE:
      return label.null;
    default:
      unreachable(type);
  }
  return label.null;
};

const deviceSizes = new Map<string, string>([
  ["XS", label.extraSmall],
  ["S", label.small],
  ["M", label.medium],
  ["L", label.large],
  ["XL", label.extraLarge]
]);

const getProductSize = (
  productId: SubscriptionProductIdMap[keyof SubscriptionProductIdMap]
): string => {
  const key = reverseMapping(SubscriptionProductId)[productId];
  const deviceSize = deviceSizes.get(key.substring(key.lastIndexOf("_") + 1));
  if (deviceSize) {
    return deviceSize;
  }
  return label.null;
};

// eslint-disable-next-line complexity
export const getLicenseType = (
  subscription: SubscriptionProductIdMap[keyof SubscriptionProductIdMap],
  detailed?: boolean
): string | undefined => {
  switch (subscription) {
    case SubscriptionProductId.L_SPAUTO_NI_IB:
    case SubscriptionProductId.L_SEC_NI_IB:
    case SubscriptionProductId.L_SEC_SP_NI_IB:
    case SubscriptionProductId.L_CLOUD_NI_IB:
      return label.essentials;
    case SubscriptionProductId.L_SPAUTO_NI_IE:
    case SubscriptionProductId.L_SEC_NI_IE:
    case SubscriptionProductId.L_SEC_SP_NI_IE:
    case SubscriptionProductId.L_CLOUD_NI_IE:
      return label.advantage;
    case SubscriptionProductId.L_SPAUTO_NI_IS:
    case SubscriptionProductId.L_SEC_NI_IS:
    case SubscriptionProductId.L_SEC_SP_NI_IS:
    case SubscriptionProductId.L_CLOUD_NI_IS:
      return label.premier;
    case SubscriptionProductId.L_TRUST_ES_EMBED:
    case SubscriptionProductId.L_CLOUD_TI_E:
    case SubscriptionProductId.L_TRUST_ES_SIAST:
    case SubscriptionProductId.L_TRUST_ES_SIA3:
    case SubscriptionProductId.L_TRUST_ES_SIA5:
    case SubscriptionProductId.L_TRUST_ES_SIA1M:
      return label.essentials;
    case SubscriptionProductId.L_TRUST_ES_GN:
    case SubscriptionProductId.L_SPAUTO_TI_E:
    case SubscriptionProductId.L_SEC_TI_E:
    case SubscriptionProductId.L_SEC_SP_TI_E:
      return label.essentialsAlaCarte;
    case SubscriptionProductId.L_TRUST_FP1_HDR:
    case SubscriptionProductId.L_TRUST_FP2_NAR:
    case SubscriptionProductId.L_TRUST_FP3_REC:
      return label.advancedAlaCarte;
    case SubscriptionProductId.XW_TA_STANDARD:
      return detailed ? label.extraLarge : label.deviceRTU;
    case SubscriptionProductId.L_SPAUTO_TA_E_XS:
    case SubscriptionProductId.L_SPAUTO_TA_E_S:
    case SubscriptionProductId.L_SPAUTO_TA_E_M:
    case SubscriptionProductId.L_SPAUTO_TA_E_L:
    case SubscriptionProductId.L_SPAUTO_TA_E_XL:
    case SubscriptionProductId.L_SEC_TA_E_XS:
    case SubscriptionProductId.L_SEC_TA_E_S:
    case SubscriptionProductId.L_SEC_TA_E_M:
    case SubscriptionProductId.L_SEC_TA_E_L:
    case SubscriptionProductId.L_SEC_TA_E_XL:
    case SubscriptionProductId.L_CLOUD_TA_E_XS:
    case SubscriptionProductId.L_CLOUD_TA_E_S:
    case SubscriptionProductId.L_CLOUD_TA_E_M:
    case SubscriptionProductId.L_CLOUD_TA_E_L:
    case SubscriptionProductId.L_CLOUD_TA_E_XL:
      return detailed ? getProductSize(subscription) : label.essentials;
    case SubscriptionProductId.SP_SVS_CNI_IB_B:
    case SubscriptionProductId.SP_SVS_CNI_IE_B:
    case SubscriptionProductId.SP_SVS_CNI_IS_B:
    case SubscriptionProductId.L_TRUST_DG_K9:
    case SubscriptionProductId.L_CLD_DG_K9:
    case SubscriptionProductId.SP_SVS_CTA_B:
    case SubscriptionProductId.SP_SVS_CTI_B:
    case SubscriptionProductId.SD_SVS_FC_IOSXR:
    case SubscriptionProductId.INVALID_PID:
      return undefined;
    default:
      unreachable(subscription);
  }
  return undefined;
};

// eslint-disable-next-line complexity
export const getSubscriptionProduct = (
  productId: SubscriptionProductIdMap[keyof SubscriptionProductIdMap],
  containerMap: CdgContainerTypeMap[keyof CdgContainerTypeMap]
): boolean => {
  switch (productId) {
    case SubscriptionProductId.L_SPAUTO_TA_E_XS:
    case SubscriptionProductId.L_SPAUTO_TA_E_S:
    case SubscriptionProductId.L_SPAUTO_TA_E_M:
    case SubscriptionProductId.L_SPAUTO_TA_E_L:
    case SubscriptionProductId.L_SPAUTO_TA_E_XL:
    case SubscriptionProductId.L_SEC_TA_E_XS:
    case SubscriptionProductId.L_SEC_TA_E_S:
    case SubscriptionProductId.L_SEC_TA_E_M:
    case SubscriptionProductId.L_SEC_TA_E_L:
    case SubscriptionProductId.L_SEC_TA_E_XL:
    case SubscriptionProductId.L_CLOUD_TA_E_XS:
    case SubscriptionProductId.L_CLOUD_TA_E_S:
    case SubscriptionProductId.L_CLOUD_TA_E_M:
    case SubscriptionProductId.L_CLOUD_TA_E_L:
    case SubscriptionProductId.L_CLOUD_TA_E_XL:
    case SubscriptionProductId.XW_TA_STANDARD:
      return containerMap === CdgContainerType.CONTAINER_FLOW;
    case SubscriptionProductId.L_SPAUTO_TI_E:
    case SubscriptionProductId.L_SEC_TI_E:
    case SubscriptionProductId.L_SEC_SP_TI_E:
    case SubscriptionProductId.L_TRUST_ES_EMBED:
    case SubscriptionProductId.L_CLOUD_TI_E:
    case SubscriptionProductId.L_TRUST_ES_SIAST:
    case SubscriptionProductId.L_TRUST_ES_SIA3:
    case SubscriptionProductId.L_TRUST_ES_SIA5:
    case SubscriptionProductId.L_TRUST_ES_SIA1M:
      return containerMap === CdgContainerType.CONTAINER_TRUST;
    case SubscriptionProductId.INVALID_PID:
    case SubscriptionProductId.SP_SVS_CTI_B:
    case SubscriptionProductId.L_SPAUTO_NI_IB:
    case SubscriptionProductId.SP_SVS_CNI_IB_B:
    case SubscriptionProductId.L_SPAUTO_NI_IE:
    case SubscriptionProductId.SP_SVS_CNI_IE_B:
    case SubscriptionProductId.L_SPAUTO_NI_IS:
    case SubscriptionProductId.L_CLOUD_NI_IB:
    case SubscriptionProductId.L_CLOUD_NI_IE:
    case SubscriptionProductId.L_CLOUD_NI_IS:
    case SubscriptionProductId.SP_SVS_CNI_IS_B:
    case SubscriptionProductId.L_TRUST_ES_GN:
    case SubscriptionProductId.L_TRUST_FP1_HDR:
    case SubscriptionProductId.L_TRUST_FP2_NAR:
    case SubscriptionProductId.L_TRUST_FP3_REC:
    case SubscriptionProductId.L_SEC_NI_IB:
    case SubscriptionProductId.L_SEC_NI_IE:
    case SubscriptionProductId.L_SEC_NI_IS:
    case SubscriptionProductId.L_SEC_SP_NI_IB:
    case SubscriptionProductId.L_SEC_SP_NI_IE:
    case SubscriptionProductId.L_SEC_SP_NI_IS:
    case SubscriptionProductId.L_TRUST_DG_K9:
    case SubscriptionProductId.SD_SVS_FC_IOSXR:
    case SubscriptionProductId.L_CLD_DG_K9:
    case SubscriptionProductId.SP_SVS_CTA_B:
      return false;
    default:
      unreachable(productId);
      return false;
  }
};

export const getFlowDeviceSizeDecription = (
  productId: SubscriptionProductIdMap[keyof SubscriptionProductIdMap]
): string => {
  let deviceSize = label.null;
  if (getSubscriptionProduct(productId, CdgContainerType.CONTAINER_FLOW)) {
    const flowPid: Partial<
      SubscriptionProductIdMap[keyof SubscriptionProductIdMap]
    > = productId;
    switch (flowPid) {
      case SubscriptionProductId.L_SPAUTO_TA_E_XS:
      case SubscriptionProductId.L_SEC_TA_E_XS:
      case SubscriptionProductId.L_CLOUD_TA_E_XS:
        deviceSize = label.flowExtraSmallShortDeviceDescription;
        break;
      case SubscriptionProductId.L_SPAUTO_TA_E_S:
      case SubscriptionProductId.L_SEC_TA_E_S:
      case SubscriptionProductId.L_CLOUD_TA_E_S:
        deviceSize = label.flowSmallShortDeviceDescription;
        break;
      case SubscriptionProductId.L_SPAUTO_TA_E_M:
      case SubscriptionProductId.L_SEC_TA_E_M:
      case SubscriptionProductId.L_CLOUD_TA_E_M:
        deviceSize = label.flowMediumShortDeviceDescription;
        break;
      case SubscriptionProductId.L_SPAUTO_TA_E_L:
      case SubscriptionProductId.L_SEC_TA_E_L:
      case SubscriptionProductId.L_CLOUD_TA_E_L:
        deviceSize = label.flowLargeShortDeviceDescription;
        break;
      case SubscriptionProductId.L_SPAUTO_TA_E_XL:
      case SubscriptionProductId.L_SEC_TA_E_XL:
      case SubscriptionProductId.L_CLOUD_TA_E_XL:
      case SubscriptionProductId.XW_TA_STANDARD:
        deviceSize = label.flowExtraLargeShortDeviceDescription;
        break;
      default:
        deviceSize = label.null;
        break;
    }
  }
  return deviceSize;
};

export const isValidRequest = (): boolean => {
  const currentUser = getCurrentUser();
  const currentClaims = currentUser && currentUser.AccessToken.claims;
  return !!(
    currentClaims?.org &&
    currentClaims?.org !== NIL_UUID &&
    currentClaims?.groups?.length &&
    currentClaims?.groups[0]
  );
};

export const getUserNameFromProvider = (value: string): string => {
  return value?.includes("/") ? value.split(/\/(.+)/)[1] : value;
};

export const getUserNameFromState = (userState: UserState): string => {
  return (
    userState?.AccessToken?.claims?.full_name ||
    userState?.AccessToken?.claims?.name
  );
};

export const appendFreeOrTrial = (
  type?: SubscriptionTypeMap[keyof SubscriptionTypeMap]
): string => {
  if (type) {
    if (type === SubscriptionType.TYPE_TRIAL) {
      return ` - ${label.trial}`;
    } else if (type === SubscriptionType.TYPE_FREE) {
      return ` - ${label.free}`;
    }
  }
  return "";
};

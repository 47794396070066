import * as React from "react";
import "./preSignup.scss";
import "./fullPage.scss";
import { Icon } from "../../components/icon/icon";
import { AnimatedLines } from "../../components/animatedLines/animatedLines";
import { Landing } from "./landing";
import { label } from "../../core/global";
import { Button } from "../../components/form/button/button";
import { Environment, getEnvironment } from "../../utils/env";
import { Constants } from "../../core/constants";
import { App } from "../../core/app";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {
  route: "trial" | "free";
}

interface State {
  createUserUrl: string | undefined;
  code: string | undefined;
  proceedToLogin: boolean;
}

class PreSignup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      createUserUrl: undefined,
      proceedToLogin: false,
      code: undefined
    };
  }

  componentDidMount(): void {
    const { route } = this.props;
    getEnvironment().then((environment: Environment) => {
      this.setState({
        createUserUrl: environment.enroll_url,
        code:
          route === "trial" ? environment.trial_token : environment.free_token
      });
    });
  }

  handleGoToNext = (): void => {
    const { route } = this.props;
    const urlStr = route === "trial" ? "?trial=" : "?free=";
    const regToken = new URLSearchParams(this.props.location.search).get(
      "regToken"
    );
    this.setState({ proceedToLogin: true }, () => {
      let url = urlStr + this.state.code;

      if (regToken) {
        url += `&regToken=${regToken}`;
      }
      window.location.assign(url);
    });
  };

  renderSteps = (): React.ReactNode => {
    const { createUserUrl } = this.state;
    const { route } = this.props;
    return (
      <div className="pre-signup full-page">
        <AnimatedLines />
        <div className="gradient-bar" />
        <div className="content">
          <div className="logo">
            <Icon name="cisco-logo" />
          </div>
          <h1 className="title">
            <span className="crosswork">{label.crosswork}</span>
            {label.cloud}
          </h1>
          <div className="pre-signup-request">
            <div className="header">
              {route === "trial"
                ? label.trialAccountRequest
                : label.newAccountRequest}
            </div>
            <div className="pre-signup-content">
              <div className="pre-signup-description">
                {label.you}
                <span className="inline-bolded">{label.must}</span>
                {route === "trial"
                  ? label.activateTrialSubscription
                  : label.activateAccount}
              </div>
              <h3 className="pre-signup-direction">
                {label.pleaseDoOneOfTheFollowing}
              </h3>
              <ol>
                <li>
                  <span>
                    {createUserUrl && (
                      <a
                        href={createUserUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link link-description"
                      >
                        {label.clickHere}
                      </a>
                    )}
                  </span>
                  {label.haveCCOAccountDescription}
                </li>
                OR
                <li>
                  {label.createCCOAccountDescription}
                  <span className="inline-bolded">{label.actions.next}</span>
                </li>
              </ol>
              <div className="pre-signup-help-description">
                {label.signupFooter}
                <a
                  href={Constants.TRIAL_HELP_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  {route === "trial" ? label.trialGuide : label.freeGuide}
                </a>
              </div>
            </div>
            <Button
              type="submit"
              classNames="primary"
              name="primary"
              onClick={this.handleGoToNext}
            >
              {label.actions.next}
            </Button>
          </div>
        </div>

        <div className="copyright">{Landing.getFooterYearValue()}</div>
      </div>
    );
  };

  render(): React.ReactNode {
    if (this.state.proceedToLogin) {
      return <App />;
    }
    return this.renderSteps();
  }
}

export default withRouter(PreSignup);

import React from "react";
import {
  isValid,
  ValidationProps,
  Validators,
  ValidationResult,
  Validatable
} from "./validation";
import { FormContextState, FormContext } from "./form";

export class ValidatedInput<
    TValue,
    TProps extends ValidationProps<TValue>,
    TState = unknown
  >
  extends React.Component<TProps, TState>
  implements Validatable
{
  static defaultProps = {
    validators: []
  };
  static contextType = FormContext;

  constructor(props: TProps) {
    super(props);
    this.state = {} as TState;
  }

  componentDidMount(): void {
    if (super.componentDidMount) {
      super.componentDidMount();
    }
    this.formContext.onFieldMount(this);
  }

  componentWillUnmount(): void {
    this.formContext.onFieldUnmount(this);
  }

  get formContext(): FormContextState {
    return this.context as FormContextState;
  }

  get isRequired(): boolean {
    return this.props.validators.some(
      (v): boolean => v === Validators.required
    );
  }

  // checks the required validator specifically, so we only use the "required" color when input is empty
  // also returns true if field is not required at all
  get isRequiredValid(): boolean {
    const requiredValidator = this.props.validators.find(
      (v): boolean => v === Validators.required
    );
    return (
      requiredValidator === undefined ||
      requiredValidator(this.props.value) === undefined
    );
  }

  get isValid(): boolean {
    return isValid(this.validate());
  }

  get validationHint(): string | undefined {
    const results = this.validate();
    return results.find((r): boolean => r !== undefined);
  }

  handleUpdate(
    newValue: TValue,
    shouldUpdateFormContext: boolean = true
  ): void {
    const validationResults = this.validate(newValue);
    const valueValid = isValid(validationResults);
    if (this.props.onChange) {
      this.props.onChange(newValue, valueValid);
    }

    if (shouldUpdateFormContext) {
      const formContext = this.formContext;
      if (formContext) {
        // logger.debug("input formContext", formContext);
        formContext.onValidate(this.props.name, newValue, validationResults);
      }
    }
  }

  // if called without arguments, tells you the validation results of the current vlaue
  // Call it with the argument newVal when raising onChange, as you want to validate
  // the changed value before it is set
  validate(val: TValue = this.props.value): ValidationResult[] {
    const { validators } = this.props;
    const validationResults = validators.map(
      (validator): ValidationResult => validator(val)
    );

    return validationResults;
  }
}

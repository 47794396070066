import React, { useEffect, useRef, useState } from "react";
import "./icon.scss";
import { UiTheme } from "generated-proto-files/auth_user_pb";
import { logger } from "../../core/global";

export type IconSize = "large" | "medium-large" | "normal" | "small";

// these values should match width/heights in icon.scss
export const ICON_SIZE_LARGE = 20;
export const ICON_SIZE_MEDIUM_LARGE = 18;
export const ICON_SIZE_NORMAL = 16;
export const ICON_SIZE_SMALL = 12;

export interface IconProps {
  name: string;
  classNames?: string;
  size?: IconSize;
}

export const themeName = (): string => {
  const theme = window.xw.preferences?.getTheme();
  switch (theme) {
    case UiTheme.DARK:
      return "dark";
    case UiTheme.LIGHT:
      return "light";
    case UiTheme.HIGH_CONTRAST:
      return "high-contrast";
    default:
      return "dark";
  }
};

export interface IconProps {
  name: string;
  classNames?: string;
  size?: IconSize;
}

const classNames = (iconProps: IconProps): string[] => {
  let classes = ["xw-icon"];
  if (iconProps.size) {
    classes.push(iconProps.size);
  }
  if (iconProps.classNames) {
    const baseClasses = iconProps.classNames.split(" ");
    classes = classes.concat(baseClasses);
  }
  return classes;
};

export const Icon = (iconProps: IconProps): JSX.Element | null => {
  const ImportedIconRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        ImportedIconRef.current = (
          await import(`../../assets/icons/${iconProps.name}.svg`)
        ).default;
      } catch (err) {
        logger.warn("icon not loaded", err);
      } finally {
        setLoading(false);
      }
    };
    importIcon();
    return () => {
      setLoading(false);
    };
  }, [iconProps.name]);

  if (!loading && ImportedIconRef.current) {
    const ImportedIcon = ImportedIconRef.current;
    return (
      //@ts-ignore
      <ImportedIcon
        className={classNames(iconProps).join(" ")}
        data-qa={iconProps.name}
      />
    );
  }

  return null;
};

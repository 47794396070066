// Does this need to be a class? Perhaps not. Optimize later

import { grpc } from "grpc-web-client";
import { ApiError } from "./apiService";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type callbackType = (data: any) => void;
type onErrorType = (err: ApiError) => void;

export class Subscriber {
  id: string;
  onUpdate: callbackType;
  onError: onErrorType;

  constructor(id: string, onUpdate: callbackType, onError: onErrorType) {
    this.id = id;
    this.onUpdate = onUpdate;
    this.onError = onError;
  }
}

export class Subscription {
  subscribers: Map<string, Subscriber>;
  dataKey: string;
  requestObject?: grpc.ProtobufMessage;
  service?: grpc.UnaryMethodDefinition<
    grpc.ProtobufMessage,
    grpc.ProtobufMessage
  >;

  constructor(
    dataKey: string,
    service?: grpc.UnaryMethodDefinition<
      grpc.ProtobufMessage,
      grpc.ProtobufMessage
    >,
    requestObject?: grpc.ProtobufMessage
  ) {
    this.dataKey = dataKey;
    this.requestObject = requestObject;
    this.service = service;
    this.subscribers = new Map();
  }

  add(subscriberId: string, subscriber: Subscriber): void {
    this.subscribers.set(subscriberId, subscriber);
  }
}

export default class Subscriptions {
  // dataKey -> map of listeners
  private subscriptions: Map<string, Subscription>;

  constructor() {
    this.subscriptions = new Map();
  }

  // will only subscribe once per dataset. yay
  public subscribe<
    TRequest extends grpc.ProtobufMessage,
    TResponse extends grpc.ProtobufMessage,
    TService extends grpc.UnaryMethodDefinition<TRequest, TResponse>
  >(
    dataKey: string,
    subscriberId: string,
    callback: callbackType,
    onError: onErrorType,
    service?: TService,
    requestObject?: TRequest
  ): void {
    if (!this.subscriptions.has(dataKey)) {
      this.subscriptions.set(
        dataKey,
        new Subscription(dataKey, service, requestObject)
      );
    }
    const subscription = this.subscriptions.get(dataKey);
    if (subscription) {
      const subscriber = new Subscriber(subscriberId, callback, onError);
      // note that there is nothing checking if the subscriber already exists in the array
      subscription.add(subscriberId, subscriber);
    }
    return;
  }

  getSubscriptions(): Subscription[] {
    return [...this.subscriptions.values()];
  }

  getSubscribers(dataKey: string): Subscriber[] {
    const subscription = this.subscriptions.get(dataKey);
    return subscription ? [...subscription.subscribers.values()] : [];
  }

  public unsubscribeAll(subscriberId: string): number {
    let killedSubscriptionCount = 0;
    this.subscriptions.forEach((subscription): void => {
      // adds to the count only if a record was actually deleted
      killedSubscriptionCount += +subscription.subscribers.delete(subscriberId);
      if (subscription.subscribers.size === 0) {
        this.subscriptions.delete(subscription.dataKey);
      }
    });
    return killedSubscriptionCount;
  }

  public unsubscribe(dataKey: string, subscriberId: string): boolean {
    const subscription = this.subscriptions.get(dataKey);
    if (subscription) {
      subscription.subscribers.delete(subscriberId);
      if (subscription.subscribers.size === 0) {
        this.subscriptions.delete(dataKey);
      }
    }
    return false;
  }
}

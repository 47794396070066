import React, { PropsWithChildren } from "react";
import "./pageSection.scss";

export interface PageSectionProps {
  classNames?: string;
  title?: string | React.ReactNode;
  actions?: React.ReactNode;
  id?: string;
  showSeparator?: boolean;
}
export class PageSection extends React.Component<
  PropsWithChildren<PageSectionProps>
> {
  render(): JSX.Element {
    const { actions, classNames, children, id, title, showSeparator } =
      this.props;
    return (
      <section
        id={id}
        className={"xw-page-section" + (classNames ? " " + classNames : "")}
      >
        {(title || actions || showSeparator) && (
          <header>
            {title && <div className="title">{title}</div>}
            <div className="spacer" />
            {actions && <div className="actions">{actions}</div>}
          </header>
        )}
        <div className="content scrollbar-large">{children}</div>
      </section>
    );
  }
}

import React, { lazy, Suspense } from "react";
import * as logger from "loglevel";
import { checkValidSession } from "../services/authService";
import AppState from "./dataflow/appState";
import Login from "../pages/session/login";
import {
  fetchMaintenanceSetting,
  getEnvName,
  isPreFree,
  isPreTrial,
  isAws,
  isSupportedBrowser
} from "../utils/env";
import { PageLoader } from "../components/loader/pageLoader";
import Maintenance from "../pages/session/maintenance";
import { EmptyProps } from "../_types/emptyUtils";
import { Landing } from "../pages/session/landing";
import PreSignup from "../pages/session/preSignup";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Constants } from "./constants";
import { Toaster } from "../components/toast/toaster";

const AwsError = lazy(() => import("../pages/settings/system/aws/awsError"));
const AWSLanding = lazy(
  () => import("../pages/settings/system/aws/awsLanding")
);
const AWSRegistrationInProgress = lazy(
  () => import("../pages/settings/system/aws/awsRegistrationInProgress")
);
const AwsRegistration = lazy(
  () => import("../pages/settings/system/aws/awsRegistration")
);
const Home = lazy(() => import("./home"));

declare global {
  interface Window {
    xw: AppState;
    logger: logger.Logger;
  }
}

interface State {
  validSession: "valid" | "invalid" | "unknown" | "presignup" | "aws";
  terminated: boolean;
  loading: boolean;
  maintenance: boolean;
}

export const initWindow = (): void => {
  // global utility namespace, this must move to typescript supported global
  window.xw = new AppState();
  window.logger = logger;
  if (getEnvName() !== "production") {
    logger.setLevel(0);
  }
  // remove static loader so that it doesn't linger in the background
  document.getElementById("app-loader")?.remove();
};

export class App extends React.Component<EmptyProps, State> {
  constructor(props: EmptyProps) {
    super(props);
    this.state = {
      validSession: "unknown",
      terminated: false,
      loading: true,
      maintenance: false
    };
    initWindow();
  }

  checkMaintenance = (): void => {
    // check override first
    const forceMaintenanceOverride = window.xw.getForceMaintenance();
    if (forceMaintenanceOverride !== undefined) {
      if (
        this.state.loading ||
        this.state.maintenance !== forceMaintenanceOverride
      ) {
        this.setState({
          maintenance: forceMaintenanceOverride,
          loading: false
        });
      }
      setTimeout(this.checkMaintenance, 60000);
      return;
    }

    // fetch ops/dev file and inspect header and content
    fetchMaintenanceSetting((maintenanceMode) => {
      if (this.state.loading || this.state.maintenance !== maintenanceMode) {
        this.setState({
          maintenance: maintenanceMode,
          loading: false
        });
      }
      setTimeout(this.checkMaintenance, 60000);
    });
  };

  checkSession(): void {
    checkValidSession()
      .then((data): void => {
        if (!data.RefreshToken || !data.RefreshToken.expired) {
          this.setState({ validSession: "valid" });
        } else {
          this.setState({ validSession: "invalid" });
        }
      })
      .catch((err): void => {
        this.setState({ validSession: "invalid" });
        logger.error(err);
      });
  }

  componentDidMount(): void {
    this.checkMaintenance();
    if (isPreFree() || isPreTrial()) {
      this.setState({ validSession: "presignup" });
    } else if (isAws()) {
      this.setState({ validSession: "aws" });
    } else {
      this.checkSession();
    }
    // provide a global hook to terminate routing immediately
    window.xw.terminateWithPrejudice = (): void => {
      logger.warn("Terminating app!");
      window.xw.cache.clear();
      this.setState({ terminated: true });
    };
  }

  render(): React.ReactNode {
    const { loading, maintenance, terminated, validSession } = this.state;
    if (loading) {
      return <PageLoader />;
    }

    if (maintenance) {
      return <Maintenance />;
    }

    if (terminated) {
      logger.info("Not rendering, app terminated");
      return <PageLoader />;
    }
    if (validSession === "valid") {
      return (
        <Suspense fallback={<PageLoader />}>
          <Home />
        </Suspense>
      );
    } else if (validSession === "presignup") {
      return <Landing route="signup" />;
    } else if (validSession === "aws") {
      if (isSupportedBrowser) {
        return (
          <Suspense fallback={<PageLoader />}>
            <HashRouter>
              <Route
                exact={true}
                path={[`${Constants.AWS_REG_INPROGRESS_URL}/:tokenId?`]}
              >
                <AWSRegistrationInProgress />
              </Route>
              <Route
                exact={true}
                path={[`${Constants.AWS_LANDING_URL}/:tokenId?`]}
              >
                <AWSLanding />
              </Route>
              <Route
                exact={true}
                path={[`${Constants.AWS_REGISTRATION_URL}/:tokenId?`]}
              >
                <AwsRegistration />
              </Route>
              <Route exact={true} path={[`${Constants.AWS_ERROR_URL}/:error?`]}>
                <AwsError />
              </Route>
              <Toaster />
            </HashRouter>
          </Suspense>
        );
      } else {
        window.xw.terminateWithPrejudice();
        window.location.hash = "/incompatible";
        window.location.reload();
      }
    } else if (validSession === "invalid") {
      return (
        <HashRouter>
          <Switch>
            <Route exact={true} path={["/signup"]}>
              <PreSignup route="free" />
            </Route>
            <Route exact={true} path={["/bgpmon"]}>
              <Landing route="bgpmon" />
            </Route>
            <Route exact={true} path={["/ccnipromo"]}>
              <Landing route="ccnipromo" />
            </Route>
            <Route>
              <Login />
            </Route>
          </Switch>
        </HashRouter>
      );
    }
    return null;
  }
}

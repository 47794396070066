import * as React from "react";
import { debounce } from "../../../utils/timing";
import { Icon } from "../../icon/icon";
import { stringAccessor, StringTransform } from "../../../utils/string";
import { ValidationProps } from "../../form/validation";
import { ValidatedInput } from "../../form/validatedInput";
import { ErrorHint } from "../errorHint";

import "./input.scss";
import { FormContext } from "../form";
import { Constants } from "../../../core/constants";
import { logger } from "../../../core/global";

export type TextInputType = "text" | "password" | "email" | "search";
export type InputModeType = "tel" | "url" | "numeric" | "decimal";

export interface InputProps extends ValidationProps {
  name: string;
  label?: string;
  type: TextInputType;
  hint?: string | StringTransform;
  disabled: boolean;
  icon?: string;
  autoComplete?: "on" | "off";
  focused?: boolean;
  onBlur?: (newVal: string, isValid?: boolean) => void;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  validateOn: "done_typing" | "blur";
  classNames?: string;
  inputMode?: InputModeType;
  mask?: RegExp;
  hideHint?: boolean;
  ariaLabel?: string;
  tabIndex?: number;
}

interface InputState {
  dirty: boolean;
  touched: boolean;
  typing: boolean;
  focused: boolean;
}

export class Input extends ValidatedInput<string, InputProps, InputState> {
  static defaultProps = {
    autoComplete: "off",
    autoSize: false,
    disabled: false,
    plain: false,
    focused: false,
    type: "text",
    tabIndex: 0,
    value: "",
    validateOn: "done_typing",
    validators: [],
    hideHint: false
  };

  static contextType = FormContext;

  private inputRef: React.RefObject<HTMLInputElement>;

  debouncedDoneTyping = debounce(
    this.doneTyping.bind(this),
    Constants.TYPING_DELAY
  );

  constructor(props: InputProps) {
    super(props);
    this.inputRef = React.createRef<HTMLInputElement>();
    this.state = {
      dirty: false,
      touched: false,
      typing: false,
      focused: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount(): void {
    super.componentDidMount();
    if (this.props.focused && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  get baseClasses(): string[] {
    // TODO: Some of this logic is repeated in select and textarea. Would be good to extract to a utility function
    const { classNames, validateOn } = this.props;
    const { dirty, focused, touched, typing } = this.state;
    const valid = this.isValid;
    const result = ["xw-input-field"];
    const required = this.isRequired;
    const requiredValid = this.isRequiredValid;
    if (classNames) {
      result.push(classNames);
    }
    result.push(valid ? "valid" : "invalid");
    if (required) {
      result.push("required");
    }
    if (this.hasValue) {
      result.push("value");
    }
    if (touched) {
      result.push("touched");
    }
    if (typing) {
      result.push("typing");
    }
    if (dirty) {
      result.push("dirty");
    }
    if (focused) {
      result.push("focused");
    }
    if (
      !valid &&
      requiredValid &&
      touched &&
      ((validateOn === "done_typing" && dirty && !typing) ||
        (validateOn === "blur" && !focused))
    ) {
      result.push("showError");
    }
    if (!valid && required && !focused && !requiredValid) {
      result.push("showRequired");
    }
    return result;
  }

  get isDirty(): boolean {
    return this.state.dirty;
  }

  get hasIcon(): boolean {
    return this.props.icon !== undefined && this.props.icon !== "";
  }

  get hasValue(): boolean {
    return this.props.value !== "";
  }

  doneTyping(): void {
    this.setState({ typing: false });
  }

  public focus(): boolean {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
    return this.inputRef.current !== undefined;
  }

  handleChange(e: React.FormEvent<HTMLInputElement>): void {
    if (this.props.mask && !this.props.mask.test(e.currentTarget.value)) {
      logger.debug(
        "Masking input on " + this.props.name + ", rejecting",
        e.currentTarget.value
      );
      return;
    }
    this.setState({ typing: true, dirty: true });
    this.debouncedDoneTyping();
    this.handleUpdate(e.currentTarget.value);
  }

  handleBlur = (e: React.FormEvent<HTMLInputElement>): void => {
    this.setState({ typing: false, focused: false });
    if (this.props.onBlur) {
      this.props.onBlur(e.currentTarget.value, this.isValid);
    }
  };

  handleFocus = (): void => {
    this.setState({
      touched: true,
      focused: true
    });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  render(): JSX.Element {
    const {
      disabled,
      icon,
      label,
      name,
      type,
      value,
      autoComplete,
      inputMode,
      onKeyDown,
      hideHint,
      ariaLabel,
      tabIndex
    } = this.props;
    return (
      <div className={this.baseClasses.join(" ")}>
        <input
          ref={this.inputRef}
          id={name}
          name={name}
          data-qa={name}
          className={this.hasValue ? "value" : ""}
          type={type}
          autoComplete={autoComplete}
          disabled={disabled}
          inputMode={inputMode}
          value={value}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={onKeyDown}
          tabIndex={tabIndex}
          aria-required={this.isRequired}
          aria-label={ariaLabel || label || name}
        />
        {label && <label htmlFor={name}>{label}</label>}
        {!hideHint && (
          <span className="hint">{stringAccessor(this.props.hint, value)}</span>
        )}
        {!hideHint && <ErrorHint>{this.validationHint}</ErrorHint>}
        {icon && <Icon name={icon} />}
        {this.props.children}
      </div>
    );
  }
}

import { BreadcrumbNode } from "../header/breadcrumbs";

// shared application state
export interface GlobalStateType {
  breadcrumbs: BreadcrumbNode[];
  title?: string;
  helpLink?: string;
  example?: string;
  // Processing modal related states and callback
  modalProcessingStateMsg?: string | JSX.Element;
  modalHeaderMsg?: string | JSX.Element;
  modalSuccessStateMsg?: string | JSX.Element;
  modalFailureStateMsg?: string | JSX.Element;
  handleModalFailureAction?: () => void; // do something when modal Failure Action button is clicked.
  handleModalSuccessAction?: () => void; // do something when modal Success Action button is clicked.
}

export const initialGlobalState = {
  breadcrumbs: [],
  modalProcessingStateMsg: undefined,
  modalSuccessStateMsg: undefined,
  modalFailureStateMsg: undefined,
  handleModalFailureAction: undefined,
  handleModalSuccessAction: undefined
};

import * as React from "react";
import { Input } from "../../components/form/input/input";
import { Button } from "../../components/form/button/button";
import { label, labelParam, logger } from "../../core/global";
import { Constants } from "../../core/constants";
import { Validators } from "../../components/form/validation";
import "./login.scss";
import "./fullPage.scss";
import { Icon } from "../../components/icon/icon";
import { AnimatedLines } from "../../components/animatedLines/animatedLines";
import { Environment, getEnvironment } from "../../utils/env";

interface Props {}

interface State {
  emailField: string;
  emailValid: boolean;
  submitButtonDisabled: boolean;
  touched: boolean;
  currentLocation: string;
  forgotPassword?: string;
  createUserUrl: string | undefined;
}

export default class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = Login.getInitialState();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    logger.info("Loading login page");
  }
  static getLocation(): string {
    let defaultUrl = `${window.location.protocol}//${window.location.host}/#/dashboard${Constants.PINNED}`;
    if (
      window.location.href ===
      `${window.location.protocol}//${window.location.host}/`
    ) {
      return defaultUrl;
    } else if (window.location.href.indexOf("invitation") > 0) {
      defaultUrl = `${window.location.protocol}//${window.location.host}${window.location.search}#/dashboard`;
    } else if (window.location.href.indexOf("login") > 0) {
      defaultUrl = `${defaultUrl}${window.location.search}`;
    } else {
      defaultUrl = `${window.location.href}`;
    }
    return defaultUrl;
  }

  static getFooterYearValue(): string {
    const year = new Date().getFullYear();
    return labelParam(label.footer, { year });
  }

  private static getInitialState(): State {
    return {
      emailField: "",
      emailValid: false,
      submitButtonDisabled: true,
      touched: false,
      currentLocation: Login.getLocation(),
      createUserUrl: undefined
    };
  }

  componentDidMount(): void {
    getEnvironment().then((environment: Environment) => {
      this.setState({
        createUserUrl: environment.enroll_url,
        forgotPassword: environment.forgot_password_url
      });
    });
  }

  handleChange(newVal: string, isValid: boolean): void {
    this.setState({
      emailField: newVal,
      submitButtonDisabled: !isValid
    });
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    if (
      Validators.required(this.state.emailField) !== undefined ||
      Validators.email(this.state.emailField) !== undefined
    ) {
      e.preventDefault();
      return;
    }
  }

  handleClick(): void {
    // no-op
  }

  handleCreateAccount = (): void => {
    window.location.assign("/#/signup");
  };

  render(): JSX.Element {
    const {
      emailField,
      submitButtonDisabled,
      currentLocation,
      forgotPassword,
      createUserUrl
    } = this.state;
    let newLocation = encodeURIComponent(currentLocation);

    const enableRemoteBackend = window.xw.getEnableRemoteBackend();
    if (enableRemoteBackend) {
      logger.warn("Note: Redirecting APIs as per ui/src/.env");
      newLocation += "&auth_bypass=true";
    }

    const content = (
      <div className="form">
        <Input
          name="email"
          type="email"
          value={emailField}
          autoComplete="off"
          onChange={this.handleChange}
          label={label.emailAddress}
          hint={label.loginMessage1}
          validators={[Validators.required, Validators.email]}
          focused={true}
        />
        <div className="buttons">
          <Button
            type="submit"
            isDisabled={submitButtonDisabled}
            classNames="primary"
            name="primary"
          >
            <span>{label.login}</span>
          </Button>
          {createUserUrl && (
            <Button type="submit" classNames="secondary signup" name="signup">
              <a href={createUserUrl} target="_blank" rel="noopener noreferrer">
                {label.signUp}
              </a>
            </Button>
          )}
        </div>
        <div className="forgot">
          {label.loginMessage2}
          {forgotPassword && (
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={forgotPassword}
              >
                {label.actions.clickHere}
              </a>
              {"."}
            </>
          )}
        </div>
        <div className="create-account">
          {label.needToCreateCCOAccount2}
          {createUserUrl && (
            <>
              <a
                href={createUserUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="link link-description"
              >
                {label.here}
              </a>
              {"."}
            </>
          )}
        </div>
        <div className="ask-assistance">
          {label.xwCloudAskAssistanceLine1}
          <a
            href={`mailto:${Constants.XW_CLOUD_ASK_ASSISTANCE_EMAIL}?subject=${label.xwCloudAskAssistanceEmailSubject}`}
          >
            {Constants.XW_CLOUD_ASK_ASSISTANCE_EMAIL}
          </a>
          {"."}
        </div>
      </div>
    );

    return (
      <div className="login-screen full-page">
        <AnimatedLines />
        <div className="gradient-bar" />

        <div className="content">
          <form
            method="POST"
            action={Constants.identityUrl("oneid") + newLocation}
            onSubmit={this.handleSubmit}
            aria-label={label.loginForm}
          >
            <div className="logo">
              <Icon name="cisco-logo" />
            </div>
            <h1 className="title">
              <span className="crosswork">{label.crosswork}</span>
              {label.cloud}
            </h1>
            {content}
          </form>
        </div>

        <div className="copyright">{Login.getFooterYearValue()}</div>
      </div>
    );
  }
}

import moment from "moment-timezone";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import * as authService from "../services/authService";
import { timeMinute } from "./d3";
import { Constants } from "../core/constants";
import { label, logger } from "../core/global";
import {
  GranularityMap,
  Granularity
} from "generated-proto-files/timeseries_api_common_pb";
import { unreachable } from "./switch";
import {
  tierRestrictedLookback,
  tierRestrictedLookbackDays
} from "../pages/settings/tier/commonTierRules";

export const localTzValue = moment.tz.guess();
export const tzOptions = [
  { label: "Local (" + localTzValue + ")", value: localTzValue },
  { label: Constants.UTC, value: Constants.UTC }
];
export const NOW_WIGGLE_ROOM = 600000; // MS offset that end date of requested duration can differ from actual time

export const addHoursToCurrentTime = (hours: number): Date => {
  const currentTime = new Date();
  currentTime.setHours(currentTime.getHours() + hours);
  currentTime.setSeconds(0);
  return currentTime;
};

export const toProtoTime = (ms: number): Timestamp => {
  const timeStamp = new Timestamp();
  timeStamp.setSeconds(Math.trunc(ms / 1000));
  return timeStamp;
};

export const tsToMilliseconds = (ts: Timestamp): number => {
  return ts.getSeconds() * 1000;
};

export type DateTimeValue = [number, number?] | undefined; // Numbers are Epoch Miliseconds, only uses first number if not range
export type DateRange = [number, number]; // Numbers are Epoch Miliseconds, only uses first number if not range

export enum TimeRangeOption {
  last15Minutes,
  lastHour,
  last3Hour,
  lastDay,
  // last3Days,
  lastWeek,
  last30Days,
  last60Days,
  last90Days,
  custom,
  lastYear
}

export type TimeRangeType = { label: string; value: string };

export const timeRangeSelectOptions = (): TimeRangeType[] => {
  return [
    {
      label: label.component.lastHour,
      value: TimeRangeOption.lastHour.toString()
    },
    {
      label: label.component.lastDay,
      value: TimeRangeOption.lastDay.toString()
    },
    // {
    //   label: label.component.last3d,
    //   value: TimeRangeOption.last3Days.toString()
    // },
    {
      label: label.component.lastWeek,
      value: TimeRangeOption.lastWeek.toString()
    },
    {
      label: label.component.last30d,
      value: TimeRangeOption.last30Days.toString()
    },
    {
      label: label.component.last90d,
      value: TimeRangeOption.last90Days.toString()
    }
  ];
};

export const bgpTimeRangeSelectOptions = (): TimeRangeType[] => {
  return [
    {
      label: label.component.lastHour,
      value: TimeRangeOption.lastHour.toString()
    },
    {
      label: label.component.lastDay,
      value: TimeRangeOption.lastDay.toString()
    },
    {
      label: label.component.lastWeek,
      value: TimeRangeOption.lastWeek.toString()
    },
    {
      label: label.component.last30d,
      value: TimeRangeOption.last30Days.toString()
    }
  ];
};

export const rovTimeRangeSelectOptions = (): TimeRangeType[] => {
  return [
    {
      label: label.component.lastHour,
      value: TimeRangeOption.lastHour.toString()
    },
    {
      label: label.component.lastDay,
      value: TimeRangeOption.lastDay.toString()
    },
    {
      label: label.component.lastWeek,
      value: TimeRangeOption.lastWeek.toString()
    }
  ];
};

export const filteredTimeRangeSelectOptions = (
  timeRange: TimeRangeType[]
): TimeRangeType[] => {
  let filterTimeRange: boolean = true;
  return timeRange.filter((e, i, options) => {
    const timeRangeValue =
      i && (Number(options[i - 1].value as unknown) as TimeRangeOption);
    const tierLookbackDays = tierRestrictedLookback();
    if (tierLookbackDays && timeRangeValue === tierLookbackDays) {
      filterTimeRange = false;
    }
    return filterTimeRange;
  });
};

// TODO what to do with the custom time range??
export const timeRangeSelectOptionsCCNI = (): TimeRangeType[] => {
  return [
    ...filteredTimeRangeSelectOptions(timeRangeSelectOptions()),
    {
      label: label.component.custom,
      value: TimeRangeOption.custom.toString()
    }
  ];
};

export const timeRangeSelectOptionsBGPUpdates = (): TimeRangeType[] => {
  return [
    ...filteredTimeRangeSelectOptions(bgpTimeRangeSelectOptions()),
    {
      label: label.component.custom,
      value: TimeRangeOption.custom.toString()
    }
  ];
};

export const timeRangeSelectOptionsROV = (): TimeRangeType[] => {
  return [
    ...filteredTimeRangeSelectOptions(rovTimeRangeSelectOptions()),
    {
      label: label.component.custom,
      value: TimeRangeOption.custom.toString()
    }
  ];
};

export type StandardTimeRangeOption = Exclude<
  TimeRangeOption,
  TimeRangeOption.custom
>;

export interface Timespan {
  start: number;
  end: number;
}

export function currentTimeRange(
  range: StandardTimeRangeOption,
  now: moment.Moment = moment().startOf("second"),
  minuteResolution: boolean = false
): [number, number] {
  // logger.debug(now.format("hh:mm:ss"));
  if (minuteResolution) {
    now.seconds(0).milliseconds(0);
  }
  switch (range) {
    case TimeRangeOption.last15Minutes:
      return [now.clone().subtract(15, "minutes").valueOf(), now.valueOf()];
    case TimeRangeOption.lastHour:
      return [now.clone().subtract(1, "hour").valueOf(), now.valueOf()];
    case TimeRangeOption.last3Hour:
      return [now.clone().subtract(3, "hour").valueOf(), now.valueOf()];
    case TimeRangeOption.lastDay:
      return [now.clone().subtract(24, "hour").valueOf(), now.valueOf()];
    // case TimeRangeOption.last3Days:
    //   return [
    //     now
    //       .clone()
    //       .subtract(72, "hour")

    //       .valueOf(),
    //     now.valueOf()
    //   ];
    case TimeRangeOption.lastWeek:
      return [
        now
          .clone()
          .subtract(24 * 7, "hour")
          .valueOf(),
        now.valueOf()
      ];
    case TimeRangeOption.last30Days:
      return [
        now
          .clone()
          .subtract(24 * 30, "hour")
          .valueOf(),
        now.valueOf()
      ];
    case TimeRangeOption.last60Days:
      return [
        now
          .clone()
          .subtract(24 * 60, "hour")
          .valueOf(),
        now.valueOf()
      ];
    case TimeRangeOption.last90Days:
      return [
        now
          .clone()
          .subtract(24 * 90, "hour")
          .valueOf(),
        now.valueOf()
      ];
    case TimeRangeOption.lastYear:
      return [now.clone().subtract(1, "year").valueOf(), now.valueOf()];
    default:
      unreachable(range);
      return [0, 0];
  }
}

export const getTimeZoneFromTimeSetting = (): string => {
  const state = authService.getCurrentUserPreferences();
  if (state?.timezone === Constants.UTC) {
    return state.timezone;
  }
  return moment.tz.guess();
};

export const getTimeFormatted = (
  val: number | moment.Moment,
  timeOnly: boolean = false
): string => {
  const timezone = getTimeZoneFromTimeSetting();
  const isUtc = timezone === Constants.UTC;
  const valMoment = typeof val === "number" ? moment(val) : val;
  if (timeOnly) {
    return valMoment.format(
      isUtc ? Constants.UTC_FORMAT_TIME : Constants.BASIC_FORMAT_TIME
    );
  }
  return valMoment.format(
    isUtc ? Constants.UTC_FORMAT : Constants.BASIC_FORMAT
  );
};

/**
 * Gets a Timespan that represents the specified TimeRangeOption at the current time.
 * Converts something abstract like "Last Hour" to 1-2pm today (if it's today and 2pm.)
 */
export function getCurrentTimespan(timeRange: TimeRangeOption): Timespan {
  const now = timeMinute.floor(new Date()).getTime();
  switch (timeRange) {
    case TimeRangeOption.last15Minutes:
      return {
        start: moment(now).add(-15, "m").valueOf(),
        end: now
      };
    case TimeRangeOption.lastHour:
      return {
        start: moment(now).add(-1, "h").valueOf(),
        end: now
      };
    case TimeRangeOption.last3Hour:
      return {
        start: moment(now).add(-3, "h").valueOf(),
        end: now
      };
    case TimeRangeOption.lastDay:
      return {
        start: moment(now).add(-1, "d").valueOf(),
        end: now
      };
    // case TimeRangeOption.last3Days:
    //   return {
    //     start: moment(now)
    //       .add(-3, "d")
    //       .valueOf(),
    //     end: now
    //   };
    case TimeRangeOption.lastWeek:
      return {
        start: moment(now).add(-7, "d").valueOf(),
        end: now
      };
    case TimeRangeOption.last30Days:
      return {
        start: moment(now).add(-30, "d").valueOf(),
        end: now
      };
    case TimeRangeOption.last60Days:
      return {
        start: moment(now).add(-60, "d").valueOf(),
        end: now
      };
    case TimeRangeOption.last90Days:
      return {
        start: moment(now).add(-90, "d").valueOf(),
        end: now
      };
    case TimeRangeOption.lastYear:
      return {
        start: moment(now).add(-1, "y").valueOf(),
        end: now
      };
    default:
      throw new Error("Unrecognized TimeRange value: " + timeRange);
  }
}

export const DeviceTimeRange = [
  {
    label: label.component.lastHour,
    value: TimeRangeOption.lastHour.toString()
  },
  {
    label: label.component.lastDay,
    value: TimeRangeOption.lastDay.toString()
  },
  {
    label: label.component.lastWeek,
    value: TimeRangeOption.lastWeek.toString()
  },
  {
    label: label.component.last30d,
    value: TimeRangeOption.last30Days.toString()
  },
  { label: label.component.custom, value: TimeRangeOption.custom.toString() }
];

export const granularityMap = new Map<
  number,
  GranularityMap[keyof GranularityMap]
>();
granularityMap.set(TimeRangeOption.last15Minutes, Granularity.PT1M);
granularityMap.set(TimeRangeOption.lastHour, Granularity.PT1M);
granularityMap.set(TimeRangeOption.lastDay, Granularity.PT30M);
granularityMap.set(TimeRangeOption.lastWeek, Granularity.PT4H);
granularityMap.set(TimeRangeOption.last30Days, Granularity.P1D);
granularityMap.set(TimeRangeOption.last60Days, Granularity.P1D);
granularityMap.set(TimeRangeOption.last90Days, Granularity.P2D);
granularityMap.set(TimeRangeOption.lastYear, Granularity.P1W);

export const numBucketsMap = new Map<TimeRangeOption, number>([
  [TimeRangeOption.last15Minutes, 15],
  [TimeRangeOption.lastHour, 60], // 1M
  [TimeRangeOption.lastDay, 48], // 30M
  // [TimeRangeOption.last3Days, 72], // 1H
  [TimeRangeOption.lastWeek, 42], // 4H
  [TimeRangeOption.last30Days, 60], // 12H
  [TimeRangeOption.last60Days, 60], // 1D
  [TimeRangeOption.last90Days, 45], // 2D
  [TimeRangeOption.lastYear, 52] // 1W
]);

export const granularityDurationMap = new Map<
  GranularityMap[keyof GranularityMap],
  number
>([
  [Granularity.PT1M, 60],
  [Granularity.PT30M, 1800],
  [Granularity.PT6H, 3600 * 6],
  [Granularity.P1D, 86400],
  [Granularity.P1W, 86400 * 7]
]);

export const getNumBuckets = (dateRange: [number, number]): number => {
  // check for tier lookback
  if (tierRestrictedLookback()) {
    const tierLookbackDays = tierRestrictedLookbackDays();
    const minDate = moment()
      .startOf("minutes")
      .subtract(tierLookbackDays ? tierLookbackDays : 90, "day")
      .valueOf();
    if (dateRange[0] <= minDate) {
      logger.warn(
        "Requested look-back range is outside the current subscription tier"
      );
      return numBucketsMap.get(TimeRangeOption.lastDay) || 6;
    }
  }
  const minutes = moment(dateRange[1]).diff(moment(dateRange[0]), "minute");
  if (minutes <= 15) {
    return numBucketsMap.get(TimeRangeOption.last15Minutes) || 6;
  }
  if (minutes <= 30) {
    return 10;
  }
  if (minutes <= 60) {
    return numBucketsMap.get(TimeRangeOption.lastHour) || 6;
  }
  if (minutes <= 1800) {
    return numBucketsMap.get(TimeRangeOption.lastDay) || 6;
  }
  if (minutes <= 10080) {
    return numBucketsMap.get(TimeRangeOption.lastWeek) || 6;
  }
  if (minutes <= 86400) {
    return numBucketsMap.get(TimeRangeOption.last30Days) || 6;
  }
  if (minutes <= 86400 * 7) {
    return numBucketsMap.get(TimeRangeOption.lastYear) || 6;
  }
  return 6;
};

const formatText = (timeText: string, num: number): string => {
  return num > 1 || num === 0
    ? `${label.last} ${num} ${timeText}s`
    : `${label.last} ${timeText}`;
};

export const prettyDuration = (
  start: moment.Moment,
  end: moment.Moment = moment()
): string => {
  const isSeconds = Math.floor(moment.duration(end.diff(start)).asSeconds());
  if (isSeconds >= 60) {
    const isMinutes = Math.floor(moment.duration(end.diff(start)).asMinutes());
    if (isMinutes >= 60) {
      const isHours = Math.floor(moment.duration(end.diff(start)).asHours());
      if (isHours >= 24) {
        const isDays = Math.floor(moment.duration(end.diff(start)).asDays());
        return formatText(label.day, isDays);
      }
      return formatText(label.hour, isHours);
    }
    return formatText(label.minute, isMinutes);
  }
  return formatText(label.second, isSeconds);
};

export const durationSelectOptionsGran1 = [
  {
    label: label.component.tenMinutes,
    value: "10"
  },
  {
    label: label.component.thirtyMinutes,
    value: "30"
  },
  {
    label: label.component.oneHour,
    value: "60"
  },
  {
    label: label.component.threeHours,
    value: "180"
  },
  {
    label: label.component.sixHours,
    value: "360"
  },
  {
    label: label.component.twelveHours,
    value: "720"
  }
];

export const durationSelectOptionsGran2 = [
  {
    label: label.component.thirtyMinutes,
    value: "30"
  },
  {
    label: label.component.oneHour,
    value: "60"
  },
  {
    label: label.component.threeHours,
    value: "180"
  },
  {
    label: label.component.sixHours,
    value: "360"
  },
  {
    label: label.component.twelveHours,
    value: "720"
  },
  {
    label: label.component.oneDay,
    value: "1440"
  },
  {
    label: label.component.threeDays,
    value: "4320"
  },
  {
    label: label.component.sevenDays,
    value: "10080"
  }
];

export const durationSelectOptionsGran3 = [
  {
    label: label.component.threeHours,
    value: "180"
  },
  {
    label: label.component.sixHours,
    value: "360"
  },
  {
    label: label.component.twelveHours,
    value: "720"
  },
  {
    label: label.component.oneDay,
    value: "1440"
  },
  {
    label: label.component.threeDays,
    value: "4320"
  },
  {
    label: label.component.sevenDays,
    value: "10080"
  }
];

// HACK: Need a less stupid way to figure this out, but it works
export function isCustomRange(value: DateTimeValue): boolean {
  const now = moment().valueOf();
  return (
    !!value &&
    !!value[1] &&
    now - value[1] > NOW_WIGGLE_ROOM &&
    now - value[1] > 0
  );
}

export const remainingDays = (
  startDate?: Timestamp,
  endDate?: Timestamp
): string => {
  let content = label.null;
  if (startDate && endDate) {
    const days = moment(endDate.getSeconds() * 1000).diff(
      moment(startDate.getSeconds() * 1000),
      "days"
    );
    content = days + " day(s)";
    if (days <= 90) {
      if (days === 0) {
        content = label.expiresToday;
      } else if (days < 0) {
        content = label.expired;
      }
    }
  }
  return content;
};

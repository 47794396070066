import { logger } from "../global";

//

type taskType = () => void;

class Schedule {
  private task: taskType;
  private cadences: Map<string, number>; // uuid -> interval
  private currentCadence: number | undefined; // lowest cadence
  private lastExecution: number | undefined;
  private nextExecution: number | undefined;
  private timeout: number | undefined;

  constructor(task: taskType) {
    this.task = task;
    this.cadences = new Map();
    this.currentCadence = undefined;
    this.lastExecution = undefined;
    this.nextExecution = undefined;
    this.timeout = undefined;
  }

  public addCadence(uuid: string, cadence: number): void {
    const currentCadence = this.currentCadence;

    this.cadences.set(uuid, cadence);
    // only recalculate if something changed (mostly it won't)
    if (currentCadence === undefined || cadence < currentCadence) {
      this.recalculateNextExecution();
    }
  }

  public removeCadence(uuid: string): void {
    this.cadences.delete(uuid);
    this.recalculateNextExecution();
  }

  private recalculateNextExecution = (): void => {
    const lowestCadence = this.getLowestCadence();
    if (lowestCadence === undefined) {
      this.nextExecution = undefined;
      this.currentCadence = undefined;
      clearTimeout(this.timeout);
      return;
    }

    if (this.lastExecution === undefined) {
      logger.debug("scheduler - new schedule, executing");
      this.executeTask();
      return;
    }

    const nextExec = this.lastExecution + lowestCadence;

    if (!this.nextExecution || nextExec !== this.nextExecution) {
      this.nextExecution = nextExec;
      clearTimeout(this.timeout);
      logger.debug(
        "scheduler - scheduling next execution: " +
          new Date(nextExec).toLocaleTimeString()
      );
      // timeout functions are really weird with TS
      this.timeout = setTimeout(
        this.executeTask,
        this.getOffset(nextExec)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any;
    }
  };

  private executeTask = (): void => {
    if (!document.hidden) {
      this.task();
    } else {
      logger.debug("scheduler - skipping execution, rescheduling");
    }
    this.lastExecution = Date.now();
    this.recalculateNextExecution();
  };

  private getLowestCadence(): number | undefined {
    let lowestCadence: number | undefined = undefined;
    this.cadences.forEach((cadence: number): void => {
      if (lowestCadence === undefined || cadence < lowestCadence) {
        lowestCadence = cadence;
      }
    });
    return lowestCadence;
  }

  // in ms
  private getOffset(nextExecution: number): number {
    return Math.max(0, nextExecution - Date.now());
  }
}

export default class Scheduler {
  private schedules: Map<string, Schedule>;

  constructor() {
    this.schedules = new Map();
  }

  public addSchedule(
    dataKey: string,
    uuid: string,
    interval: number,
    task: taskType
  ): void {
    let schedule = this.schedules.get(dataKey);
    if (!schedule) {
      schedule = new Schedule(task);
      this.schedules.set(dataKey, schedule);
    }
    if (schedule) {
      schedule.addCadence(uuid, interval);
    }
  }

  public removeSchedule(dataKey: string, uuid: string): void {
    const schedule = this.schedules.get(dataKey);
    if (schedule) {
      schedule.removeCadence(uuid);
    }
  }

  public removeAllSchedule(uuid: string): void {
    this.schedules.forEach((schedule): void => {
      schedule.removeCadence(uuid);
    });
  }
}

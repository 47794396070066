import React, { PropsWithChildren } from "react";
import "./button.scss";

export interface ButtonProps {
  name: string;
  isDisabled?: boolean;
  type: "submit" | "button" | "reset";
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  classNames?: string;
  ariaLabel?: string;
  style?: React.CSSProperties;
}

interface ButtonState {
  buttonClickStatus: boolean;
}

export class Button extends React.Component<
  PropsWithChildren<ButtonProps>,
  ButtonState
> {
  static defaultProps = {
    isDisabled: false,
    type: "button",
    classNames: ""
  };

  rootRef = React.createRef<HTMLButtonElement>();

  constructor(props: ButtonProps) {
    super(props);
    this.state = {
      buttonClickStatus: false
    };
  }

  handleOnClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState({ buttonClickStatus: true });
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render(): JSX.Element {
    const { isDisabled, name, onFocus, type, style, onMouseDown } = this.props;
    const baseClasses = [];
    baseClasses.push("xw-button");
    baseClasses.push(this.props.classNames);
    if (this.state.buttonClickStatus) {
      baseClasses.push("active");
    }

    return (
      <button
        aria-label={this.props.ariaLabel || name}
        className={baseClasses.join(" ")}
        disabled={isDisabled}
        onClick={this.handleOnClick}
        onMouseDown={onMouseDown}
        onFocus={onFocus}
        data-qa={name}
        type={type}
        name={name}
        style={style}
        ref={this.rootRef}
      >
        {this.props.children}
      </button>
    );
  }
}

import * as React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import * as toastService from "../../services/toastService";
import { Constants } from "../../core/constants";
import logger from "loglevel";

import "./toaster.scss";
import { Toast } from "./toast";
import uuidv4 from "../../utils/uuid";
import ReactDOM from "react-dom";
import { EmptyProps } from "../../_types/emptyUtils";

interface ToastMessageWithId extends toastService.ToastMessage {
  id: string;
}

interface State {
  currentToasts: ToastMessageWithId[];
}

export class Toaster extends React.Component<EmptyProps, State> {
  private toasterRoot = document.querySelector("#toaster") as Element;

  constructor(props: EmptyProps) {
    super(props);
    this.state = {
      currentToasts: []
    };
  }

  componentDidMount(): void {
    toastService.events.on(toastService.QUEUE_EVENT, this.handleToastQueued);
  }

  componentWillUnmount(): void {
    toastService.events.off(toastService.QUEUE_EVENT, this.handleToastQueued);
  }

  closeToast(toastId: string): void {
    logger.debug("closing toast " + toastId);
    this.setState((prevState): State => {
      return {
        currentToasts: prevState.currentToasts.filter(
          (toast): boolean => toast.id !== toastId
        )
      };
    });
  }

  handleCloseOf(toastId: string): () => void {
    return (): void => {
      this.closeToast(toastId);
    };
  }

  handleToastQueued = (): void => {
    this.updateFromQueue();
  };

  updateFromQueue = (): void => {
    if (
      this.state.currentToasts.length < Constants.TOAST_MAX &&
      toastService.queueLength() > 0
    ) {
      const newToast = {
        id: uuidv4(),
        ...toastService.dequeue()
      };

      // Do not show messages that are already being shown
      if (
        this.state.currentToasts.some((msg: ToastMessageWithId): boolean => {
          return msg.message === newToast.message;
        })
      ) {
        return;
      }

      this.setState((prevState): State => {
        return {
          currentToasts: prevState.currentToasts.concat(newToast)
        };
      });
    }
  };

  componentDidUpdate(): void {
    // toasts may have been removed, so check if we should pop from the queue again
    this.updateFromQueue();
  }

  render(): JSX.Element {
    const { currentToasts } = this.state;
    /* eslint-disable react/forbid-component-props */
    return ReactDOM.createPortal(
      <TransitionGroup component={null}>
        {currentToasts.map(
          (toast): JSX.Element => (
            <CSSTransition key={toast.id} timeout={500} classNames="toast-item">
              <Toast
                toast={toast}
                key={toast.id}
                onClose={this.handleCloseOf(toast.id)}
              />
            </CSSTransition>
          )
        )}
      </TransitionGroup>,
      this.toasterRoot
    );
  }
}

import * as React from "react";
import "./processingLoader.scss";

export class ProcessingLoader extends React.Component {
  render(): JSX.Element {
    return (
      <div className="xw-processing-loader">
        <svg
          className="loader"
          viewBox="0 -10 202 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            className="rect rect-1"
            x="0"
            y="30"
            width="9px"
            height="9px"
            rx="5"
            ry="5"
          >
            <animate
              attributeName="height"
              begin="0s"
              values="9; 18; 9; 9"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              begin="0s"
              values="30; 21; 30; 30"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect
            className="rect rect-2"
            x="24"
            y="30"
            width="9px"
            height="9px"
            rx="5"
            ry="5"
          >
            <animate
              attributeName="height"
              begin="0.08s"
              values="9; 30; 9; 9"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              begin="0.08s"
              values="30; 9; 30; 30"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect
            className="rect rect-3"
            x="48"
            y="30"
            width="9px"
            height="9px"
            rx="5"
            ry="5"
          >
            <animate
              attributeName="height"
              begin="0.16s"
              values="9; 55; 9; 9"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              begin="0.16s"
              values="30;-7; 30; 30"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect
            className="rect rect-4"
            x="72"
            y="30"
            width="9px"
            height="9px"
            rx="5"
            ry="5"
          >
            <animate
              attributeName="height"
              begin="0.24s"
              values="9; 30; 9; 9"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              begin="0.24s"
              values="30; 9; 30; 30"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect
            className="rect rect-5"
            x="96"
            y="30"
            width="9px"
            height="9px"
            rx="5"
            ry="5"
          >
            <animate
              attributeName="height"
              begin="0.32s"
              values="9; 18; 9; 9"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              begin="0.32s"
              values="30; 21; 30; 30"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect
            className="rect rect-6"
            x="120"
            y="30"
            width="9px"
            height="9px"
            rx="5"
            ry="5"
          >
            <animate
              attributeName="height"
              begin="0.4s"
              values="9; 30; 9; 9"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              begin="0.4s"
              values="30; 9; 30; 30"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect
            className="rect rect-7"
            x="144"
            y="30"
            width="9px"
            height="9px"
            rx="5"
            ry="5"
          >
            <animate
              attributeName="height"
              begin="0.48s"
              values="9; 55; 9; 9"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              begin="0.48s"
              values="30;-7; 30; 30"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect
            className="rect rect-8"
            x="168"
            y="30"
            width="9px"
            height="9px"
            rx="5"
            ry="5"
          >
            <animate
              attributeName="height"
              begin="0.56s"
              values="9; 30; 9; 9"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              begin="0.56s"
              values="30; 9; 30; 30"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
          </rect>
          <rect
            className="rect rect-9"
            x="192"
            y="30"
            width="9px"
            height="9px"
            rx="5"
            ry="5"
          >
            <animate
              attributeName="height"
              begin="0.64s"
              values="9; 18; 9; 9"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              begin="0.64s"
              values="30; 21; 30; 30"
              keyTimes="0; 0.4; 0.8; 1"
              dur="1.1s"
              repeatCount="indefinite"
            />
          </rect>
        </svg>
      </div>
    );
  }
}
